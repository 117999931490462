import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useAppSelector } from "app/hooks";
import {
    Box,
    Button,
} from "@mui/material";
import useStyles from "./HeaderStyle";
import GRFlag from "../../Assets/Icons/GR.png";
import EnFlag from "../../Assets/Icons/EN.png";
import Api from "../../api/Api";
import { deleteCookie } from "../../utils";
import Config from "../../app-config";
import buildTitle from "../titleHelper";
import ChangePasswordModal from "./ChangePasswordModal";

const Header = React.forwardRef(({ isHome }: { isHome?: boolean }, ref): ReactElement => {
    const classes = useStyles();
    const { t, i18n } = useTranslation("common");
    const [isPasswordChangeVisible, setIsPasswordChangeVisible] = useState<boolean>(false);
    const { currentPage } = useAppSelector(state => state.appState);
    const history = useHistory();

    const handleLanguageChange = async (): Promise<void> => {
        if (i18n.language === "de") {
            await i18n.changeLanguage("en-gb")
            buildTitle(history.location.pathname)
        } else {
            await i18n.changeLanguage("de");
            buildTitle(history.location.pathname)
        }
    }

    const handleSignOut = (): void => {
        deleteCookie('token');
        Api.setToken(null);
        history.push("/login");
    }

    return (
        <Box ref={ref} flexDirection="row" display="flex"
             sx={{ bgcolor: isHome ? "lightgray" : "primary.main", padding: 2 }}>
            {isHome &&
                <ChangePasswordModal isVisible={isPasswordChangeVisible} onClose={() => setIsPasswordChangeVisible(false)} />
            }
            <Typography variant="h6" noWrap className={classes.title} sx={{ color: isHome ? "black" : "white" }}>
                {t(`AppBar.${currentPage}`)}
            </Typography>
            <div className={classes.RightIcon}>
                {isHome &&
                    <div>
                        <Button onClick={() => setIsPasswordChangeVisible(true)} color="secondary">
                            {t("change_password")}
                        </Button>
                    </div>
                }
                <div>
                    {!Config.language &&
                        <IconButton onClick={handleLanguageChange}>
                            <img src={i18n.language === "de" ? EnFlag : GRFlag} alt="Language"/>
                        </IconButton>
                    }
                </div>
                <div>
                    <IconButton onClick={handleSignOut}>
                        <i className="fas fa-sign-out-alt"
                           style={{ color: isHome ? "black" : "white", fontSize: "large" }}/>
                    </IconButton>
                </div>
            </div>
        </Box>
    );
})

Header.defaultProps = {
    isHome: false
}

export default Header;
