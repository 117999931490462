import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from "react-redux";
import { ObjectSchema } from "yup";
import { CustomEndAdornment, FormattedNumberOrTextInput, isFieldValid } from "ndr-designsystem";
import { Close } from "@mui/icons-material";
import useStyles from "./styles";
import {
    allowedCodes as AllowedGridElementCodingSchemeCodes,
    GridElementCodingScheme
} from "../../api/fixed/Sensitivity/GridElementCodingScheme";
import { allowedCodes as AllowedDirectionCodes } from "../../api/fixed/FlexibilityConstraint/Direction";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { setAddNewSimplePlanningData } from "../appStateSlice";
import SensitivityDto from "../../api/dtos/Sensitivity/SensitivityDto";
import { transformToSelectEntry } from "../../utils";
import { GridElementSensitivitiesSchema } from "../../utils/inputChecking";
import Direction from "../../api/fixed/Sensitivity/Direction";

interface SensitivityFormDto {
    id?: string,
    intervalStart: string | null,
    intervalEnd: string | null,
    revision: number,
    gridElementSensitivities: {
        gridElementCode: string,
        gridElementCodingScheme: GridElementCodingScheme,
        gridElementId?: string,
        quantity: number,
    }[]
    direction: Direction
}

// this is required because useFieldArray doesn't correctly work when the field is named 'id'
// the problem is that sometimes, the validation shows the id field as empty although it's not.
function mapToForm(dto: SensitivityDto): SensitivityFormDto {
    return {
        ...dto, gridElementSensitivities: [...dto.gridElementSensitivities.map(x => {
            const result = { ...x, gridElementId: x.id };
            delete result.id;
            return result;
        })]
    };
}

function mapFromForm(dto: SensitivityFormDto): SensitivityDto {
    return {
        ...dto, gridElementSensitivities: [...dto.gridElementSensitivities.map(x => {
            const result = { ...x, id: x.gridElementId };
            delete result.gridElementId;
            return result;
        })]
    };
}

interface Props {
    open: boolean
    handleClose: () => void
    allItemsSelector: (state: RootState) => any[]
    createItem: any
    updateItem: any
    schema: (otherItems: any[]) => ObjectSchema<any>
    buildDefaultItem: () => any,
    translationNamespace: string
}

function AddDialog({
                       open,
                       handleClose,
                       allItemsSelector,
                       updateItem,
                       createItem,
                       schema,
                       buildDefaultItem,
                       translationNamespace
                   }: Props): ReactElement {
    const otherItems: SensitivityDto[] = useSelector(allItemsSelector);
    const [isSaving, setIsSaving] = useState(false);
    const { addNewSimplePlanningData } = useAppSelector((state: RootState) => state.appState)
    const resolver = useMemo(() => schema(otherItems.filter(item => item.id !== addNewSimplePlanningData.data?.id)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [addNewSimplePlanningData, schema])

    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        control,
        setValue,
        getValues,
        clearErrors,
        setError,
        trigger
    } = useForm<SensitivityFormDto>({
        mode: "all",
        resolver: yupResolver(resolver),
        // @ts-ignore empty input
        defaultValues: addNewSimplePlanningData.data ? mapToForm(addNewSimplePlanningData.data) : buildDefaultItem()
    });

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "gridElementSensitivities", // unique name for your Field Array
    });
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (addNewSimplePlanningData) {
            setValue("direction", { code: "A01" });
        }
    }, [addNewSimplePlanningData, setValue])

    const allowedGridElementCodingSchemeCodes = useMemo(() => transformToSelectEntry(AllowedGridElementCodingSchemeCodes, t, "api:grid_element_coding_scheme"), [t])
    const allowedDirectionCodes = useMemo(() => transformToSelectEntry(AllowedDirectionCodes, t, "api:direction"), [t])

    const onSubmit: SubmitHandler<SensitivityDto> = async (data) => {
        setIsSaving(true);
        if (addNewSimplePlanningData.data) {
            await dispatch(updateItem({
                id: addNewSimplePlanningData.id,
                data: {
                    ...mapFromForm(data),
                    id: addNewSimplePlanningData.data.id
                },
            }))
        } else {
            await dispatch(createItem({
                id: addNewSimplePlanningData.id,
                data: mapFromForm(data),
            }))
        }
        await dispatch(setAddNewSimplePlanningData({
            show: false,
            id: "",
            data: undefined,
            addType: "sensitivity"
        }))
        setIsSaving(false)
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{t(`tables:${translationNamespace}.title`)}</DialogTitle>
            <DialogContent>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Controller control={control} name='direction' render={({ field, fieldState }) =>
                        <FormControl className={classes.formControl}
                                     fullWidth
                                     variant="standard"
                                     error={fieldState.error !== undefined}
                        >
                            <InputLabel id="directionLabel">{t(`tables:${translationNamespace}.direction`)}</InputLabel>
                            <Select
                                disabled={addNewSimplePlanningData.data != null}
                                labelId="directionLabel"
                                label={t(`tables:${translationNamespace}.direction`)}
                                onChange={e => {
                                    const newEvent = { ...e, target: { ...e.target, value: { code: e.target.value } } }
                                    field.onChange(newEvent)
                                    trigger()
                                }}
                                value={field.value?.code ?? ''}
                                onBlur={field.onBlur}
                            >
                                {allowedDirectionCodes.map(val =>
                                    <MenuItem key={val.code} value={val.code}>{val.name}</MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{fieldState.error ? t(`${fieldState.error.message}`) : ""}</FormHelperText>
                        </FormControl>
                    }/>
                    <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between"
                         sx={{ marginBottom: 1 }}
                    >
                        <Box width="49%" display="flex">
                            <Controller control={control} name='intervalStart'
                                        render={({ field, fieldState }) => <FormControl
                                            className={classes.spacedFormControl}
                                            error={fieldState.error != null}
                                        >
                                            <DesktopDatePicker
                                                renderInput={(params) => <TextField name={field.name}
                                                                                    variant="standard" {...params} />}
                                                value={field.value}
                                                onChange={(date: DateTime | null) => {
                                                    const newEvent = {
                                                        target: {
                                                            value: date?.startOf('day').toISO()
                                                        }
                                                    }
                                                    field.onChange(newEvent);
                                                    trigger()
                                                }}
                                                label={t(`tables:${translationNamespace}.start_date`)}
                                            />
                                            <FormHelperText>{fieldState.error ? t(`${fieldState.error.message}`) : ""}</FormHelperText>
                                        </FormControl>}
                            />
                        </Box>
                        <Box width="49%" display="flex">
                            <Controller control={control} name='intervalEnd'
                                        render={({ field, fieldState }) => <FormControl
                                            className={classes.formControl}
                                            error={fieldState.error != null}
                                        >
                                            <DesktopDatePicker
                                                value={field.value}
                                                renderInput={(params) => <TextField variant="standard" {...params} />}
                                                onChange={(date: DateTime | null) => {
                                                    const newEvent = {
                                                        target: {
                                                            value: date?.startOf('day').toISO()
                                                        }
                                                    }
                                                    field.onChange(newEvent);
                                                    trigger()
                                                }}
                                                label={t(`tables:${translationNamespace}.end_date`)}
                                            />
                                            <FormHelperText>{fieldState.error ? t(`${fieldState.error.message}`) : ""}</FormHelperText>
                                        </FormControl>}/>
                        </Box>
                    </Box>
                    {fields.map((fieldArrayWithId, index) =>
                        <Box display="flex" flexDirection="column"
                             width="100%"
                             alignItems="center"
                             justifyContent="center" key={fieldArrayWithId.id}
                             sx={{ marginBottom: 1 }}
                             border={0.5}>
                            <Box display="flex" flexDirection="row" width="100%" alignItems="center"
                                 justifyContent="center"
                                 sx={{ borderBottom: 1 }}>
                                <Typography fontSize={14}>

                                    {t(`tables:${translationNamespace}.grid_connection_point`)}
                                </Typography>

                                <IconButton disableRipple onClick={() => {
                                    const value = getValues('gridElementSensitivities').reduce((v1, v2, idx) => v1 + (idx === index ? 0 : (v2.quantity ?? 0)), 0)
                                    if (value === 100) {
                                        clearErrors('gridElementSensitivities')
                                    } else {
                                        setError('gridElementSensitivities', { message: 'validation:add_sensitivity.quantity_exactly_100' })
                                    }
                                    remove(index)
                                }} sx={{
                                    position: "absolute",
                                    left: "93%",
                                    transform: 'translate(-50%)'
                                }}>
                                    <Close sx={{ fontSize: 14 }}/>

                                </IconButton>
                            </Box>
                            <Box display="flex" flexDirection="column" width="90%"
                                 justifyContent="space-between">
                                <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                                    <input type="hidden" value={fieldArrayWithId.gridElementId} name={`gridElementSensitivities.${index}.gridElementId`}/>
                                    <Box width="49%" display="flex">
                                        <Controller control={control}
                                                    name={`gridElementSensitivities.${index}.gridElementCodingScheme`}
                                                    render={({ field, fieldState }) =>
                                                        <FormControl className={classes.codingSchemaFormControl}
                                                                     fullWidth
                                                                     variant="standard"
                                                                     error={fieldState.error !== undefined}
                                                        >
                                                            <InputLabel
                                                                id="gridElementCodingScheme">{t(`tables:${translationNamespace}.grid_element_coding_scheme`)}</InputLabel>
                                                            <Select
                                                                disableUnderline
                                                                labelId="gridElementCodingScheme"
                                                                label={t(`tables:${translationNamespace}.grid_element_coding_scheme`)}
                                                                onChange={e => {
                                                                    const newEvent = {
                                                                        ...e,
                                                                        target: {
                                                                            ...e.target,
                                                                            value: { code: e.target.value }
                                                                        }
                                                                    }
                                                                    field.onChange(newEvent)
                                                                    trigger()
                                                                }}
                                                                value={field.value?.code ?? ''}
                                                                onBlur={field.onBlur}
                                                            >
                                                                {allowedGridElementCodingSchemeCodes.map(val =>
                                                                    <MenuItem key={val.code}
                                                                              value={val.code}>{val.name}</MenuItem>
                                                                )}
                                                            </Select>
                                                            <FormHelperText>{fieldState.error ? t(`${fieldState.error.message}`) : ""}</FormHelperText>
                                                        </FormControl>
                                                    }/>
                                    </Box>
                                    <Box width="49%" display="flex">
                                        <FormControl className={classes.formControl}
                                                     error={errors?.gridElementSensitivities && errors?.gridElementSensitivities[index]?.gridElementCode !== undefined}
                                        >
                                            <TextField
                                                {...register(`gridElementSensitivities.${index}.gridElementCode`)}
                                                variant="standard"
                                                autoFocus={false}
                                                label={t(`tables:${translationNamespace}.code`)}
                                                fullWidth
                                            />
                                            <FormHelperText>{errors?.gridElementSensitivities && errors?.gridElementSensitivities[index]?.gridElementCode ? t(errors?.gridElementSensitivities[index]?.gridElementCode?.message ?? "") : ""}</FormHelperText>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                            <Controller
                                control={control} name={`gridElementSensitivities.${index}.quantity`}
                                render={({ field, fieldState }) =>
                                    <FormControl
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "90%",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            margin: 2
                                        }}
                                        error={fieldState.error != null || errors?.gridElementSensitivities != null}
                                    >
                                        <Box width="100%" display="flex" flexDirection="row"
                                             alignItems="center"
                                             justifyContent="space-between">
                                            <Box width="80%">
                                                <Typography>
                                                    {t(`tables:${translationNamespace}.quantity`)}
                                                </Typography>
                                            </Box>
                                            <Box width="20%" display="flex">
                                                <FormattedNumberOrTextInput
                                                    maxFractionDigits={3}
                                                    defaultValue={field.value ?? ""}
                                                    type="number"
                                                    onBlur={field.onBlur}
                                                    isEditing
                                                    alwaysNotify
                                                    InputProps={{
                                                        endAdornment: <CustomEndAdornment content="%"/>
                                                    }}
                                                    showTooltip={false}
                                                    onValueChange={val => {
                                                        const value = getValues('gridElementSensitivities').reduce((v1, v2, idx) => v1 + (idx === index ? val : (v2.quantity ?? 0)), 0)
                                                        if (value === 100) {
                                                            clearErrors('gridElementSensitivities')
                                                        } else {
                                                            setError('gridElementSensitivities', { message: 'validation:add_sensitivity.quantity_exactly_100' })
                                                        }
                                                        field.onChange(val)
                                                        trigger();
                                                    }}
                                                    valueCheckFunction={quantity => isFieldValid(GridElementSensitivitiesSchema(), ["quantity"], { quantity })}
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>
                                        {fieldState.error ?
                                            <FormHelperText>{t(`${fieldState.error.message}`)}</FormHelperText> :
                                            // @ts-ignore
                                            <FormHelperText>{t(errors?.gridElementSensitivities?.message ?? "")}</FormHelperText>
                                        }
                                    </FormControl>
                                }/>
                        </Box>)}

                    <Box display="flex" width="100%" flexDirection="column" alignItems="center">
                        <Button
                            onClick={() => {
                                const value = getValues('gridElementSensitivities').reduce((v1, v2) => v1 + (v2.quantity ?? 0), 0)
                                append({
                                    quantity: 100 - value > 0 ? 100 - value : 0,
                                    gridElementCode: '',
                                    gridElementCodingScheme: undefined
                                })
                                if (value === 100) {
                                    clearErrors('gridElementSensitivities')
                                } else {
                                    setError('gridElementSensitivities', { message: 'validation:add_sensitivity.quantity_exactly_100' })
                                }
                            }}
                            color="primary">
                            {t(`tables:${translationNamespace}.buttons.add_new_grid_element`)}
                        </Button>
                    </Box>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {t(`tables:${translationNamespace}.buttons.cancel`)}
                        </Button>
                        <Button type="submit" color="primary" disabled={!isValid || isSaving}>
                            {isSaving ?
                                <CircularProgress color="primary" variant="indeterminate"/> :
                                t(`tables:${translationNamespace}.buttons.create`)
                            }
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddDialog;
