import { createDateTime } from "ndr-designsystem";
import { PlanningDataDetails } from "../../fixed/PlanningData/PlanningDataDetails";
import {
    fromDto as fromPlannedResourceTimeSeriesDto,
    PlannedResourceTimeSeriesDto
} from "./PlannedResourceTimeSeriesDto";

export interface PlanningDataDetailsDto {
    internalID: string;
    date?: string;
    timeSeries: PlannedResourceTimeSeriesDto[];
}

export const fromDto = (dto: PlanningDataDetailsDto): PlanningDataDetails => ({
    ...dto,
    date: dto.date ? createDateTime(dto.date) : undefined,
    timeSeries: dto.timeSeries ? dto.timeSeries.map(ts => fromPlannedResourceTimeSeriesDto(ts)) : undefined
});
