import { useSelector } from "react-redux";
import React, { ReactElement, useCallback, useMemo } from "react";
import { Field, isFieldValid } from "ndr-designsystem"
import { useTranslation } from "react-i18next";
import {
    setAccountingModel,
    setAdditionalAffectedGridOperators,
    setAffectedGridOperators,
    setCompensationType,
    setControlArea,
    setEnergySource,
    setExternalID,
    setName,
    setOperationsManager,
    swapAdditionalAffectedGridOperators,
    swapAffectedGridOperators,
    updateNewAdditionalAffectedGridOperator,
    updateNewAffectedGridOperator
} from "../../store/controllableResourcesSlice";
import {
    areMarketPartnersSame,
    buildMarketPartnerText,
    getMarketPartnersId,
    transformToSelectEntry
} from "../../../../utils";
import SchemaConstants from "../../../../utils/schemaConsts";
import GeneralGridOperatorsTable from "../../../../Components/GeneralGridOperatorsTable";
import { allowedCodes as EnergySourceAllowedCodes } from "../../../../api/fixed/ControllableResource/EnergySource";
import { allowedCodes as ControlAreaAllowedCodes } from "../../../../api/fixed/ControllableResource/ControlArea";
import {
    allowedCodes as CompensationTypeAllowedCodes
} from "../../../../api/fixed/ControllableResource/CompensationType";
import { allowedCodes as AccountingModelAllowedCodes } from "../../../../api/fixed/ControllableResource/AccountingModel";
import { PageStatus } from "../../../../utils/types";
import { CRGeneralTabSchema } from "../../../../utils/inputChecking";
import { RootState } from "../../../../app/store";
import { useAppDispatch } from "../../../../app/hooks";
import { setAddNewMarketPartnerData } from "../../../appStateSlice";

interface Props {
    pageStatus: PageStatus
}

const General = ({ pageStatus }: Props): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { t } = useTranslation("tables");
    const {
        allPartners: marketPartners,
        currentTenantMarketPartner
    } = useSelector((state: RootState) => state.marketPartners);
    const { currentResource: resource, allResources } = useSelector((state: RootState) => state.controllableResources);
    const prepareMP = useCallback(() => getMarketPartnersId(marketPartners), [marketPartners]);

    const getAffectedGridOperatorsActions = useCallback(() => ({
        setGridOperators: setAffectedGridOperators,
        updateNewGridOperator: updateNewAffectedGridOperator,
        swapGridOperators: swapAffectedGridOperators
    }), []);

    const getAdditionalAffectedGridOperatorsActions = useCallback(() => ({
        setGridOperators: setAdditionalAffectedGridOperators,
        updateNewGridOperator: updateNewAdditionalAffectedGridOperator,
        swapGridOperators: swapAdditionalAffectedGridOperators
    }), []);

    const energySourceAllowedValues = useMemo(() => transformToSelectEntry(EnergySourceAllowedCodes, t, "api:energy_source"), [t])
    const controlAreaAllowedValues = useMemo(() => transformToSelectEntry(ControlAreaAllowedCodes, t, "api:control_area"), [t])
    const compensationTypeAllowedValues = useMemo(() => transformToSelectEntry(CompensationTypeAllowedCodes, t, "api:compensation_type"), [t])
    const accountingModelAllowedValues = useMemo(() => transformToSelectEntry(AccountingModelAllowedCodes, t, "api:accounting_model"), [t])

    const operationsManagerDefaultValue = useCallback(() => {
        if (resource?.operationsManagerId) {
            const marketPartner = marketPartners?.find(p => p.inventoryItemId === resource.operationsManagerId);

            if (marketPartner) {
                return {
                    id: marketPartner.inventoryItemId,
                    text: buildMarketPartnerText(marketPartner)
                };
            }
            return undefined;
        }
        return undefined;
    }, [marketPartners, resource?.operationsManagerId])

    const prepareMarketPartnersList = useCallback(() => marketPartners?.map(value => ({
        id: `${value.inventoryItemId}`,
        text: buildMarketPartnerText(value)
    })) ?? [], [marketPartners])
    const getAllResourcesIDs = useCallback((): string[] => (allResources ?? []).filter(r => r.inventoryItemId !== resource?.inventoryItemId).map(r => r.externalID ?? ""), [allResources, resource])

    const GeneralTabSchema = CRGeneralTabSchema(prepareMP(), marketPartners?.filter(mp => areMarketPartnersSame(mp, currentTenantMarketPartner)), getAllResourcesIDs());
    const dispatch = useAppDispatch();

    if (resource === undefined || resource === null) return <></>

    return (
        <>
            <Field
                inputType="controlledText"
                onValueChange={val => dispatch(setExternalID(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={externalID => isFieldValid(GeneralTabSchema, [SchemaConstants.externalID], { externalID })}
                defaultValue={resource.externalID}
                isFilledByEiv
                filledByEivText={t("filled_by_eiv")}
                title={t("controllable_resources_details.externalID")}
                description={t("controllable_resources_details.externalIDDescription")}
            />
            <Field
                onValueChange={val => dispatch(setName(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={name => isFieldValid(GeneralTabSchema, [SchemaConstants.name], { name })}
                defaultValue={resource.name}
                inputType="controlledText"
                isFilledByEiv
                filledByEivText={t("filled_by_eiv")}
                title={t("controllable_resources_details.name")}
                description={t("controllable_resources_details.nameDescription")}
            />
            <GeneralGridOperatorsTable
                showLabel
                firstElementCurrentTenantMarketPartner
                addNewMarketPartnerData={{ type: "control", fieldPath: "affectedGridOperators" }}
                validationResult={isFieldValid(GeneralTabSchema, [SchemaConstants.affectedGridOperatorIds], { affectedGridOperatorIds: resource.affectedGridOperatorIds })}
                showIndex
                newElementPosition="bottom"
                title={t("controllable_resources_details.affectedGridOperators")}
                description={t("controllable_resources_details.affectedGridOperatorsDescription")}
                elements={resource.affectedGridOperatorIds}
                actions={getAffectedGridOperatorsActions()}
                pageStatus={pageStatus}
            />
            <GeneralGridOperatorsTable
                showLabel={false}
                addNewMarketPartnerData={{ type: "control", fieldPath: "additionalGridAffectedOperators" }}
                validationResult={isFieldValid(GeneralTabSchema, [SchemaConstants.additionalAffectedGridOperatorIds], { additionalAffectedGridOperatorIds: resource.additionalAffectedGridOperatorIds })}
                showIndex={false}
                title={t("controllable_resources_details.additionalAffectedGridOperators")}
                description={t("controllable_resources_details.additionalAffectedGridOperatorsDescription")}
                elements={resource.additionalAffectedGridOperatorIds}
                actions={getAdditionalAffectedGridOperatorsActions()}
                pageStatus={pageStatus}
            />
            {marketPartners && marketPartners.length > 0 &&
                <Field
                    allowAdd
                    onAddNewClick={() => {
                        dispatch(setAddNewMarketPartnerData({
                            show: true,
                            type: "control",
                            fieldPath: "operationsManagerId",
                            data: undefined
                        }))
                    }}
                    onValueChange={val => dispatch(setOperationsManager(val?.id))}
                    isEditing={isEditing || isAdding}
                    isFilledByEiv
                    filledByEivText={t("filled_by_eiv")}
                    valueCheckFunction={opMng => isFieldValid(GeneralTabSchema, [SchemaConstants.operationsManagerId], { operationsManagerId: opMng?.id })}
                    defaultValue={resource.operationsManagerId && operationsManagerDefaultValue()}
                    title={t('controllable_resources_details.operationsManager')}
                    description={t('controllable_resources_details.operationsManagerDescription')}
                    values={prepareMarketPartnersList()}
                    keyField='id'
                    textField='text'
                    inputType='select'
                />
            }
            <Field
                onValueChange={val => dispatch(setEnergySource(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={energySource => isFieldValid(GeneralTabSchema, [SchemaConstants.energySource], { energySource })}
                title={t('controllable_resources_details.energySource')}
                values={energySourceAllowedValues}
                defaultValue={energySourceAllowedValues.find(f => f.code === resource.energySource?.code)}
                keyField='code'
                textField='name'
                inputType='select'
            />
            <Field
                onValueChange={val => dispatch(setControlArea(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={controlArea => isFieldValid(GeneralTabSchema, [SchemaConstants.controlArea], { controlArea })}
                title={t('controllable_resources_details.controlArea')}
                description={t('controllable_resources_details.controlAreaDescription')}
                values={controlAreaAllowedValues}
                defaultValue={controlAreaAllowedValues.find(f => f.code === resource.controlArea?.code)}
                keyField='code'
                textField='name'
                inputType='select'
            />
            <Field
                onValueChange={val => dispatch(setCompensationType(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={compensationType => isFieldValid(GeneralTabSchema, [SchemaConstants.compensationType], { compensationType })}
                title={t('controllable_resources_details.compensationType')}
                values={compensationTypeAllowedValues}
                defaultValue={compensationTypeAllowedValues.find(f => f.code === resource.compensationType?.code)}
                keyField='code'
                textField='name'
                inputType='select'
            />
            <Field
                onValueChange={val => dispatch(setAccountingModel(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={accountingModel => isFieldValid(GeneralTabSchema, [SchemaConstants.accountingModel], { accountingModel })}
                title={t("controllable_resources_details.accountingModel")}
                description={t("controllable_resources_details.accountingModelDescription")}
                values={accountingModelAllowedValues}
                defaultValue={accountingModelAllowedValues.find(f => f.code === resource.accountingModel?.code)}
                keyField='code'
                textField='name'
                inputType='select'
            />
        </>
    )
}

export default General;
