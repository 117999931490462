import { createDateTime } from "ndr-designsystem";
import { PlanningData } from "../../fixed/PlanningData/PlanningData";

export interface PlanningDataDto {
    internalID: string;
    date?: string;
    numberOfTimeSeries: number;
    dispatches: { dispatchId: string }[]
}

export const fromDto = (dto: PlanningDataDto): PlanningData => ({
    ...dto,
    date: dto.date ? createDateTime(dto.date) : undefined
});
