import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GridDataState, GridField, TranslatedApiValueCell } from "ndr-designsystem";
import { GridFieldElement } from "ndr-designsystem/dist/utils";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import ReceivedMessage from "../../api/fixed/ConnectPlusMessage/ReceivedMessage";
import SentMessage from "../../api/fixed/ConnectPlusMessage/SentMessage";
import Created from "./columns/Created";
import Ack from "./columns/Ack";
import Files from "./columns/Files";
import ViewErrors from "./columns/ViewErrors";
import RowRender from "./RowRender";
import DropdownFilterCell from "../DropdownFilterCell";
import {
    allowedMessageTypes,
    allowedReceivedMessageStatuses,
    allowedSentMessageStatuses
} from "../../api/fixed/ConnectPlusMessage/ConnectPlusMessage";

type Props = {
    data: SentMessage[] | undefined,
    onViewErrorsClick: (errors: string[]) => void,
    direction: "sent"
    toolbar?: ReactElement
    onDataStateChange?: (newState: GridDataState) => void,
    processData?: boolean,
    totalMessages?: number
    filterable: boolean
} | {
    data: ReceivedMessage[] | undefined,
    onViewErrorsClick: (errors: string[]) => void,
    direction: "received"
    toolbar?: ReactElement
    onDataStateChange?: (newState: GridDataState) => void
    processData?: boolean,
    totalMessages?: number,
    filterable: boolean
}

const ConnectPlusMessagesGrid = ({
                                     data,
                                     onViewErrorsClick,
                                     direction,
                                     toolbar,
                                     onDataStateChange,
                                     processData,
                                     totalMessages,
                                     filterable
                                 }: Props): ReactElement => {
    const { t } = useTranslation("tables");

    const gridFields = useMemo(() => {
        const fields: GridFieldElement[] = [
            {
                name: "messageType",
                title: t("connect_plus_messages.messageType"),
                filterable: true,
                filterCell: (props: GridFilterCellProps) => (
                    <DropdownFilterCell
                        {...props}
                        namespace="api:connect_plus_message_type"
                        data={allowedMessageTypes}
                        defaultItem="All"
                    />
                )
            },
            {
                name: "createdAt",
                title: t('connect_plus_messages.date'),
                customCell: Created
            },
            {
                name: "state",
                title: t("connect_plus_messages.state"),
                customCell: ({ dataItem }) => <TranslatedApiValueCell
                    value={dataItem.state}
                    namespace={direction === "sent" ? "tables:sent_message_state" : "tables:received_message_state"}
                />,
                filterable: true,
                filterCell: (props: GridFilterCellProps) => (
                    <DropdownFilterCell
                        {...props}
                        namespace={direction === "sent" ? "tables:sent_message_state" : "tables:received_message_state"}
                        data={direction === "sent" ? allowedSentMessageStatuses : allowedReceivedMessageStatuses}
                        defaultItem="All"
                    />
                )
            },
            {
                name: "ackCreatedAt",
                title: t("connect_plus_messages.ackDate"),
                customCell: Ack
            }
        ]
        if (direction === "received") {
            fields.push({
                name: "ackStatus",
                title: t("connect_plus_messages.ackState")
            })
        }
        fields.push({
            title: t("connect_plus_messages.files"),
            customCell: props => <Files {...props} direction={direction}/>
        })
        fields.push({
            title: t("connect_plus_messages.errors"),
            name: "xmlFileName",
            customCell: props => <ViewErrors {...props} onViewErrorsClick={onViewErrorsClick}/>
        })

        return fields
    }, [direction, onViewErrorsClick, t])

    return <GridField
        values={data}
        gridFields={gridFields}
        rowRender={(el, props) => RowRender(el, { ...props, direction })}
        dataItemKey="internalId"
        defaultOpen
        noRecordsText={t('no_records_text')}
        customToolbar={toolbar}
        onDataStateChange={onDataStateChange}
        processData={processData}
        filterable={filterable}
        totalItems={totalMessages}
    />
}

ConnectPlusMessagesGrid.defaultProps = {
    toolbar: undefined
}

export default ConnectPlusMessagesGrid
