import { ControllableResource } from "../fixed/ControllableResource/ControllableResource";

export interface ControllableResourceDto {
    externalID?: string,
    name?: string,
    affectedGridOperatorIds?: string[]
    additionalAffectedGridOperatorIds?: string[]
    operationsManagerId?: string
    energySource?: string
    compensationType?: string
    isTolerationCase?: boolean
    fixation?: string
    controllabilityType?: number
    unit?: string
    levels?: number[]
    stepLength?: number
    minSteps?: number
    maxSteps?: number
    activationType?: string
    accountingModel?: string
    controlArea?: string
    minPower?: number
    minOperatingTime?: number
    minDownTime?: number
    rampupTimeCold?: number
    rampupTimeHot?: number
    startupTimeCold?: number
    startupTimeHot?: number
    shutdownTime?: number
    ratedCapacityGradient?: number
    ratedCapacityGradientUnit?: string
    ratedCapacityGradientBaseSize?: number
    minCapacityGradient?: number
    minCapacityGradientUnit?: string
    minCapacityGradientBaseSize?: number
    revision?: number,
    hasSuccessfulDispatch?: boolean
}

export const toDto = (original: ControllableResource): ControllableResourceDto => {
    const backup = { ...original }

    delete backup.inventoryItemId
    delete backup.energySource
    delete backup.compensationType
    delete backup.fixation
    delete backup.unit
    delete backup.activationType
    delete backup.accountingModel
    delete backup.controlArea
    delete backup.ratedCapacityGradientUnit
    delete backup.minCapacityGradientUnit

    return {
        ...backup,
        energySource: original.energySource?.code,
        compensationType: original.compensationType?.code,
        fixation: original.fixation?.code,
        unit: original.unit?.code,
        activationType: original.activationType?.code,
        accountingModel: original.accountingModel?.code,
        controlArea: original.controlArea?.code,
        ratedCapacityGradientUnit: original.ratedCapacityGradientUnit?.code,
        minCapacityGradientUnit: original.minCapacityGradientUnit?.code,
        isTolerationCase: original.isTolerationCase === true,
        // @ts-ignore
        levels: original.levels?.filter(i => i != null) ?? []
    }
}