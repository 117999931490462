import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { ReactElement, useCallback } from "react";
import { Alert, Snackbar } from "@mui/material";
import { DateTime } from "luxon";
import Typography from "@mui/material/Typography";
import AddMarketPartnerDialog from "../../features/marketpartner/AddMarketPartnerDialog";
import {
    setAddNewCostInfo,
    setAddNewMarketPartnerData,
    setAddNewSimplePlanningData,
    setLastActionResult,
    setMessageErrors,
    setNotificationAlertMessage,
    setTenantAccessDenied,
} from "../../features/appStateSlice";
import AlertDialog from "../../Components/AlertDialog/SimpleAlertDialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { deleteCookie, SlideTransition } from "../../utils";
import Api from "../../api/Api";
import ViewErrorsDialog from "../../features/ConnectPlusMsgs/ViewErrorsDialog";
import AddSensitivityDialog from "../../features/simplePlanningData/AddDialog";
import { allSensitivitiesSelector } from "../../features/simplePlanningData/sensitivities/store/slice";
import { createSensitivity, updateSensitivity } from "../../features/simplePlanningData/sensitivities/store/thunks";
import { SensitivitySchema } from "../../utils/inputChecking";
import PlanningDataSendingStatusDialog from "../../features/timeseries/planningData/SendingStatusDialog";
import ConnectPlusDetailsDialog from "../../features/ConnectPlusMsgs/ConnectPlusDetailsDialog";
import AddCostInfoDialog from "../../features/costInfo/AddDialog";
import GeneratedCostInfoSendingStatusDialog from "../../features/timeseries/generatedCostInfo/SendingStatusDialog";
import GeneratedSensitivitySendingStatusDialog
    from "../../features/timeseries/generatedSensitivities/SendingStatusDialog";

const AppAlerts = (): ReactElement => {
    const {
        addNewMarketPartner,
        tenantAccessDenied,
        notificationAlertMessage,
        messageErrors,
        addNewSimplePlanningData,
        lastActionResult,
        addNewCostInfo
    } = useAppSelector(state => state.appState)

    const {
        sendingStatusGeneratedCostInfo
    } = useAppSelector(state => state.generatedCostInfo)

    const {
        sendingStatusGeneratedSensitivity
    } = useAppSelector(state => state.generatedSensitivity)
    const {
        sendingStatusPlanningData
    } = useAppSelector(state => state.planningData)
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { t } = useTranslation("common");

    const handleLogout = useCallback((): void => {
        deleteCookie('token');
        Api.setToken(null);
        history.push("/login");
        dispatch(setTenantAccessDenied(false))
        // dispatch doesn't change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history])

    const handleViewed = useCallback(() => {
        dispatch(setNotificationAlertMessage(undefined));
        // dispatch doesn't change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOnClose = (): void => {
        dispatch(setLastActionResult(undefined));
    }

    const buildDefaultItem = (): any => ({
        intervalStart: null,
        intervalEnd: null,
        // @ts-ignore empty input
        gridElementSensitivities: [],
        direction: undefined,
    })

    const buildProps = (): any => ({
        createItem: createSensitivity,
        schema: SensitivitySchema,
        updateItem: updateSensitivity,
        allItemsSelector: allSensitivitiesSelector,
        translationNamespace: "sensitivities_add_dialog"
    })

    return (
        <>
            {messageErrors.length > 0 &&
                <ViewErrorsDialog open={messageErrors.length > 0} handleClose={() => dispatch(setMessageErrors([]))}/>
            }
            {addNewMarketPartner.show &&
                <AddMarketPartnerDialog
                    open={addNewMarketPartner.show}
                    handleClose={() => dispatch(setAddNewMarketPartnerData({
                        show: false,
                        type: "control",
                        fieldPath: "affectedGridOperators",
                        elementIndex: 0,
                        data: undefined
                    }))}
                />
            }
            {addNewSimplePlanningData.show &&
                <AddSensitivityDialog
                    {...buildProps()}
                    open={addNewSimplePlanningData.show}
                    handleClose={() => dispatch(setAddNewSimplePlanningData({
                        show: false,
                        id: "",
                        data: undefined,
                        addType: "sensitivity"
                    }))}
                    buildDefaultItem={buildDefaultItem}
                />
            }
            {addNewCostInfo.show &&
                <AddCostInfoDialog
                    open={addNewCostInfo.show}
                    handleClose={() => dispatch(setAddNewCostInfo({
                        show: false,
                        id: "",
                        data: undefined,
                    }))}
                />
            }
            {sendingStatusPlanningData !== undefined &&
                <PlanningDataSendingStatusDialog/>
            }
            {sendingStatusGeneratedCostInfo !== undefined &&
                <GeneratedCostInfoSendingStatusDialog/>
            }
            {sendingStatusGeneratedSensitivity !== undefined &&
                <GeneratedSensitivitySendingStatusDialog/>
            }
            {tenantAccessDenied &&
                <AlertDialog
                    text={t('alert_messages.tenant_access_denied')}
                    open={tenantAccessDenied}
                    acceptText={t("alert_messages.logout")}
                    onAgree={handleLogout}
                />
            }
            {notificationAlertMessage &&
                <AlertDialog
                    text={t(notificationAlertMessage)}
                    open
                    onCancel={handleViewed}
                    acceptText={t('alert_messages.ok')}
                    onAgree={handleViewed}
                />
            }
            <ConnectPlusDetailsDialog/>
            <Snackbar TransitionComponent={SlideTransition}
                      anchorOrigin={{ horizontal: "right", vertical: "top" }}
                      open={lastActionResult !== undefined}
                      autoHideDuration={2000}
                      onClose={handleOnClose}
            >
                {lastActionResult !== undefined ?
                    <Alert severity={lastActionResult.status ? "success" : "error"}
                           sx={{ width: '100%', alignItems: "center" }}>
                        {lastActionResult.status ? "Success!" : <Typography variant="body2">
                            {lastActionResult.message && lastActionResult.message.length > 0 ? lastActionResult.message : "Something happened, please retry"}
                            <br/>
                            {lastActionResult.message && lastActionResult.message.length > 0 ? "" : DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
                        </Typography>
                        }
                    </Alert> : <div/>
                }
            </Snackbar>

        </>
    )
}

export default React.memo(AppAlerts)
