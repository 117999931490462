import { GridDataState, GridField, TranslatedApiValueCell } from "ndr-designsystem";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridCellProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { DateTime } from "luxon";
import { Box, Button, IconButton } from "@mui/material";
import DropdownFilterCell from "Components/DropdownFilterCell";
import { isEqual } from "lodash";
import { GridFieldElement } from "ndr-designsystem/dist/utils";
import { Add, Remove } from "@mui/icons-material";
import DateCell from "../../Components/DateCell";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { allTasksSelector, setExpandedItemId } from "./store/store";
import { setMessageErrors } from "../appStateSlice";
import { fetchTasks, resetTasksState } from "./store/thunks";
import { allowedValues as allowedTaskStatusValues } from "../../api/fixed/Task/TaskStatus";
import { allowedValues as allowedTaskTypeValues } from "../../api/fixed/Task/TaskType";
import DetailsView from "./details/DetailsView";
import DateTimeFilterCell from "../../Components/DateTimeFilterCell";
import Task from "../../api/fixed/Task/Task";
import { TaskError } from "../../api/fixed/Task/TaskError";
import { fetchControlGroups } from "../ControlGroups/store/thunks";

const TasksView = (): ReactElement => {
    const { t } = useTranslation("tables")
    const tasks = useAppSelector(allTasksSelector, (left, right) => isEqual(left, right))
    const { totalItems, expandedItemId } = useAppSelector(state => state.tasks)
    const dispatch = useAppDispatch();
    const [dataState, setDataState] = useState<GridDataState | undefined>(undefined);

    useEffect(() => dispatch(fetchControlGroups(false)));
    useEffect(() => () => dispatch(resetTasksState()), [dispatch])

    useEffect(() => {
        if (dataState) {
            dispatch(fetchTasks(dataState));
        }
    }, [dataState, dispatch])

    const tasksWithExpandedProps = useMemo(() => {
        if (tasks) {
            return tasks.map(task => ({ ...task, showDetails: task.primaryTaskId === expandedItemId }))
        }
        return undefined
    }, [expandedItemId, tasks])

    const gridFields = (): GridFieldElement[] => [
        {
            customCell: (props: GridCellProps) => {
                const { dataItem }: { dataItem: Task } = props
                return <td>
                    <IconButton
                        onClick={() => dispatch(setExpandedItemId(dataItem.primaryTaskId))}>
                        {expandedItemId === dataItem.primaryTaskId ? <Remove style={{ fontSize: 16 }}/> :
                            <Add style={{ fontSize: 16 }}/>}
                    </IconButton>
                </td>
            }
        },
        {
            title: t("tasks.type"),
            customCell: ({ dataItem }) => <TranslatedApiValueCell
                value={dataItem.type}
                namespace="api:task_type"
            />,
            filterable: true,
            name: "type",
            filterCell: (props: GridFilterCellProps) => (
                <DropdownFilterCell
                    {...props}
                    namespace="api:task_type"
                    data={allowedTaskTypeValues}
                    defaultItem="All"
                />
            )
        },
        {
            title: t("tasks.started_at"),
            name: "startedAt",
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="startedAt"
                displayFormat={DateTime.DATETIME_MED_WITH_SECONDS}
            />,
            filterCell: (props: GridFilterCellProps) => (
                <DateTimeFilterCell
                    {...props}
                    namespace="api:filter.date"
                    defaultOperator={(dataState?.filter?.filters.find((f: any) => f.field === "startedAt") as any)?.operator ?? "gte"}
                />
            ),
            filterType: "date",
            filterable: true
        },
        {
            title: t("tasks.completed_at"),
            name: "completedAt",
            customCell: (props: GridCellProps) => <DateCell
                {...props}
                fieldPath="completedAt"
                displayFormat={DateTime.DATETIME_MED_WITH_SECONDS}
            />,
            filterCell: (props: GridFilterCellProps) => (
                <DateTimeFilterCell
                    {...props}
                    namespace="api:filter.date"
                    defaultOperator={(dataState?.filter?.filters.find((f: any) => f.field === "completedAt") as any)?.operator ?? "gte"}
                />
            ),
            filterType: "date",
            filterable: true
        },
        {
            title: t("tasks.status"),
            name: "status",
            customCell: ({ dataItem }) => <TranslatedApiValueCell
                value={dataItem.status}
                namespace="api:task_status"
            />,
            filterable: true,
            filterCell: (props: GridFilterCellProps) => (
                <DropdownFilterCell
                    {...props}
                    namespace="api:task_status"
                    data={allowedTaskStatusValues}
                    defaultItem="All"
                />
            )
        },
        {
            title: t("tasks.errors"),
            customCell: (props: GridCellProps) => <td>
                {props.dataItem.errors && props.dataItem.errors.length > 0 &&
                    <Button color="secondary"
                            onClick={() => dispatch(setMessageErrors(props.dataItem.errors.map((err: TaskError) => t(err.message || ''))))}>
                        {t('connect_plus_messages.view_errors')}
                    </Button>
                }
            </td>
        },
    ]

    return (
        <Box width="100%" height="100%">
            <GridField
                filterable
                totalItems={totalItems}
                values={tasksWithExpandedProps}
                gridFields={gridFields()}
                detailComponent={DetailsView}
                noRecordsText={t('no_records_text')}
                dataItemKey="primaryTaskId"
                onRowClick={e => dispatch(setExpandedItemId(e.dataItem.primaryTaskId))}
                defaultOpen
                processData={false}
                onDataStateChange={async (newState) => {
                    if (isEqual(dataState, newState)) return;

                    if (!isEqual(dataState?.filter, newState.filter) || !isEqual(dataState?.sort, newState.sort)) {
                        dispatch(resetTasksState());
                    }

                    setDataState(newState);
                }}
            />
        </Box>
    )
}

export default TasksView;
