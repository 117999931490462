export const getNestedPropValue = (item: any, field: string): any => {
    const nestedPropertiesOfField = field.split(".");
    let newItem = item;
    let val: any;
    nestedPropertiesOfField.forEach(prop => {
        val = newItem[prop];
        newItem = newItem[prop];
    })
    return val;
}

export const compareAscByField = (item1: any, item2: any, field: string): number => {
    const val1 = getNestedPropValue(item1, field);
    const val2 = getNestedPropValue(item2, field);

    if (val1 < val2)
        return 1;
    if (val1 > val2)
        return -1;
    return 0;
}

export const sameOrGreater = (elt: any, idx: number, arr: any[]): boolean => {
    const prev = arr[idx - 1];
    return !idx || elt >= prev;
}


export const compareDescByField = (item1: any, item2: any, field: string): number => {
    const val1 = getNestedPropValue(item1, field);
    const val2 = getNestedPropValue(item2, field);

    if (val1 < val2)
        return -1;
    if (val1 > val2)
        return 1;
    return 0;
}

export const commonElementsBetweenCollectionsByProperty = (property: string, a: any[], b: any[]): any[] =>
    a.filter(r => b.find(r2 => r2 === r[property]) !== undefined)

export function removeNulls(obj: any): any {
    if (obj === null) {
        return undefined;
    }
    if (typeof obj === 'object') {
        Object.keys(obj).forEach(key => {
            // eslint-disable-next-line no-param-reassign
            obj[key] = removeNulls(obj[key]);
        })
    }
    return obj;
}

export const getCookieValue = (name: string): string | null => (
    document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || null
)

export function deleteCookie(name: string): void {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}