import { ControlGroup } from "../fixed/ControlGroup/ControlGroup";

export interface ControlGroupDto {
    externalID?: string
    name?: string
    affectedGridOperators?: string[]
    additionalAffectedGridOperators?: string[]
    fixation?: string
    controllabilityType?: number
    unit?: string
    levels?: number[]
    stepLength?: number
    minSteps?: number
    maxSteps?: number
    minimumMinutesRequiredBeforeActivation?: number
    controllableResources?: string[]
    dispatchExternalIds?: string[],
    hasSuccessfulDispatch?: boolean
}

export const toDto = (group: ControlGroup): ControlGroupDto => {
    const backup = { ...group }

    delete backup.inventoryItemId
    delete backup.fixation
    delete backup.unit
    delete backup.levels
    delete backup.revision

    return {
        ...backup,
        fixation: group.fixation?.code,
        unit: group.unit?.code,
        // @ts-ignore
        levels: (group.levels ?? []).filter(i => i != null)
    }
}

