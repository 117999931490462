import React, { ReactElement, useMemo } from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import { DateTime } from "luxon";
import { createDateTime, toLocaleDate } from "ndr-designsystem";
import { useAppSelector } from "../../../app/hooks";
import CustomChart from "../../../Components/Chart";
import { currentActivationSelector } from "./store/store";
import PeriodInterval from "../../../api/fixed/Activations/PeriodInterval";

interface DataSets {
    labels: DateTime[],
    values: number[],
    backgroundColors: string[],
    borderColors: string[]
}

const TimeSeriesChart = (): ReactElement => {
    const currentActivation = useAppSelector(currentActivationSelector, (left, right) => left?.id === right?.id);
    const { direction } = useAppSelector(state => state.activations)
    const internationalization = useInternationalization();

    const unit = useMemo(() => {
        if (!currentActivation || !direction)
            return "";
        switch (direction) {
            case "up":
                if(currentActivation.upTimeSeries?.measureUnit.code === "MAW")
                    return "MW";
                return "%";
            case "down":
                if(currentActivation.downTimeSeries?.measureUnit.code === "MAW")
                    return "MW";
                return "%";
            case "avat":
                return "%";
            case "absolute":
                return "MW";
            case "delta":
                return "MW";
            default:
                return ""
        }
    }, [currentActivation, direction]);

    function getValues(data: PeriodInterval[] | undefined): DataSets {
        const factor = unit === "%" ? 100 : 1;
        const result = data?.map(x => {
            const value = x.quantity * factor;
            return {
                label: createDateTime(x.timestamp),
                value,
                backgroundColor: x.isActive ? "#345a05" : "#ef870f",
                borderColor: x.isActive ? "#83b008" : "#e8cab9"
            };
        }) || [];

        return {
            labels: result.map(x => x.label),
            values: result.map(x => x.value),
            backgroundColors: result.map(x => x.backgroundColor),
            borderColors: result.map(x => x.borderColor)
        };
    }

    const chartData = useMemo(() => {
        if (currentActivation && direction) {
            let dataSets: DataSets;
            switch (direction) {
                case "up": {
                    dataSets = getValues(currentActivation.upTimeSeries?.periodIntervals)
                    break;
                }
                case "down": {
                    dataSets = getValues(currentActivation.downTimeSeries?.periodIntervals);
                    break;
                }
                case "avat": {
                    dataSets = getValues(currentActivation.avatTimeSeriesValue);
                    break;
                }
                case "absolute": {
                    dataSets = getValues(currentActivation.absoluteTimeSeriesValue);
                    break;
                }
                case "delta": {
                    dataSets = getValues(currentActivation.deltaTimeSeriesValue);
                    break;
                }
                default:
                    dataSets = { labels: [], values: [], backgroundColors: [], borderColors: [] }
            }

            const { labels, values, backgroundColors, borderColors } = dataSets;
            return {
                labels,
                datasets: [{
                    data: values,
                    borderColor: "#cccccc",
                    pointBackgroundColor: backgroundColors,
                    pointBorderColor: borderColors
                }]
            };
        }
        return { labels: [""], datasets: [] };
    }, [currentActivation, direction]);

    const getChartPlugins = useMemo(() => ({
        tooltip: {
            callbacks: {
                label: (item: any) => {
                    if(item.dataset.pointBorderColor[item.dataIndex] === "#e8cab9")
                        return "Abruf nicht aktiv";

                    return `Abruf aktiv: ${item.formattedValue} ${unit}`;
                },
                title: (items: any) => toLocaleDate(items[0].parsed.x, internationalization.locale, DateTime.DATETIME_SHORT)
            },
        },
        legend: {
            display: false
        },
    }), [internationalization, unit])

    const getChartScales = useMemo(() => ({
        y: {
            ticks: {
                callback: (value: number) => `${internationalization.formatNumber(value, {
                    useGrouping: true,
                    maximumFractionDigits: 3
                })} ${unit}`
            },
            offset: true
        },
        x: {
            adapters: {
                date: {
                    zone: "Europe/Berlin"
                }
            },
            type: "time",
            time: {
                unit: "minute",
                ticks: {
                    source: "data",
                },
                stepSize: 15,
                displayFormats: {
                    hour: internationalization.locale === "de" ? "HH" : "h a",
                    minute: internationalization.locale === "de" ? "HH:mm" : "h:mm a",
                    second: internationalization.locale === "de" ? "HH:mm:ss" : "h:mm:ss a"
                }
            },
            offset: true
        },
    }), [internationalization, unit])

    return <CustomChart
        maintainAspectRatio={false}
        locale={internationalization.locale}
        plugins={getChartPlugins}
        scales={getChartScales}
        data={chartData}
        zoom={false}
    />
}

export default TimeSeriesChart;
