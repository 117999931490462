import { Box, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PageStatus } from "ndr-designsystem";
import { useAppSelector } from "../../../app/hooks";
import ControllableResourceStatus, {
    stableControllableResourceStates
} from "../../../api/fixed/ControllableResource/ControllableResourceStatus";
import SentIndicator from "../list/columns/SentIndicator";
import EivIndicator from "../list/columns/EivIndicator";
import useStyles from "../styles";

const Status = ({ pageStatus }: { pageStatus: PageStatus }): ReactElement => {
    const { currentResource: resource } = useAppSelector(state => state.controllableResources)
    const classes = useStyles()
    const { t } = useTranslation("tables");
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;

    if (!resource) return <></>
    const translatedStatusName = stableControllableResourceStates.includes(resource.status ?? ControllableResourceStatus.Created) && !isEditing && resource.status && resource.status.length ? t(`controllable_resources_details.states.${resource.status}`) : null;
    return (
        <>
            {resource.status === ControllableResourceStatus.Accepted &&
                <Box className={classes.acceptedStyle} width="100%" height={40}>
                    <Box display="flex"
                         justifyContent="space-between" alignItems="center" px={2} py={1}>
                        <Typography sx={{ width: "100%" }}>
                            {t("controllable_resources_details.status")}: {translatedStatusName}
                        </Typography>
                        {resource.receivedFromEiv &&
                            <EivIndicator controllableResource={resource} alwaysShowText/>
                        }
                        <SentIndicator controllableResource={resource} alwaysShowText/>
                    </Box>
                </Box>
            }
            {resource.status === ControllableResourceStatus.Rejected &&
                <Box className={classes.rejectedStyle} width="100%" height={40}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={1}>
                        <Typography sx={{ width: "100%" }}>
                            {t("controllable_resources_details.status")}: {translatedStatusName}
                        </Typography>
                        {resource.receivedFromEiv &&
                            <EivIndicator controllableResource={resource} alwaysShowText/>
                        }
                        <SentIndicator controllableResource={resource} alwaysShowText/>
                    </Box>
                </Box>
            }
            {(resource.status !== ControllableResourceStatus.Accepted &&
                    resource.status !== ControllableResourceStatus.Rejected &&
                    resource.hasSuccessfulDispatch &&
                    !isAdding && !!translatedStatusName) &&
                <Box width="100%"
                     className={classes.hasSuccessfulDispatchStyle} height={40}>
                    <Box display="flex"
                         justifyContent="space-between" alignItems="center" px={2} py={1}>
                        <Typography sx={{ width: "100%" }}>
                            {t("controllable_resources_details.status")}: {translatedStatusName}
                        </Typography>
                        {resource.receivedFromEiv &&
                            <EivIndicator controllableResource={resource} alwaysShowText/>
                        }
                        <SentIndicator controllableResource={resource} alwaysShowText/>
                    </Box>
                </Box>
            }
            {(resource.status !== ControllableResourceStatus.Accepted &&
                    resource.status !== ControllableResourceStatus.Rejected &&
                    !resource.hasSuccessfulDispatch &&
                    !isAdding && !!translatedStatusName) &&
                <Box width="100%" height={40}>
                    <Box display="flex"
                         justifyContent="space-between" alignItems="center" px={2} py={1}>
                        <Typography sx={{ width: "100%" }}>
                            {t("controllable_resources_details.status")}: {translatedStatusName}
                        </Typography>
                        {resource.receivedFromEiv &&
                            <EivIndicator controllableResource={resource} alwaysShowText/>
                        }
                        <SentIndicator controllableResource={resource} alwaysShowText/>
                    </Box>
                </Box>
            }
        </>
    )
}

export default Status
