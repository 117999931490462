import React, { useCallback, useEffect, useRef, useState } from "react";
import { List } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { CustomTabTitle, isFieldValid, LoadingComponent } from "ndr-designsystem"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PageStatus } from "./types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fullResourcesDataSelector, setCurrentGroup, setStatus } from "./store/controlGroupsSlice";
import { CGControllabilityTabSchema, CGCRTableSchema, CGGeneralTabSchema, CGSchema } from "../../utils/inputChecking";
import ControllabilitySubGrid from "./ControllabilitySubGrid";
import ControllableResourcesTable from "./ControllableResourcesTable";
import {
    addNewControlGroup,
    buildCGState,
    confirmGroup as confirmGroupAction,
    fetchControlGroups,
    fetchControlGroupStatusById,
    forceRefreshCG,
    resetCGBeforeEdit,
    resetCGState,
    updateControlGroup
} from "./store/thunks";
import DetailsViewToolbar from "../../Components/DetailsViewToolbar";
import { areMarketPartnersSame, getMarketPartnersId } from "../../utils";
import { setCurrentPage, } from "../appStateSlice";
import useStyles from "./styles";
import ControlGroupStatus, { stableControlGroupStates } from "../../api/fixed/ControlGroup/ControlGroupStatus";
import ActivationsTable from "./ActivationsTable";
import ConnectPlusMsgsTable from "./ConnectPlusMsgsTable";
import GeneralTab from "./GeneralTab";
import ProgressBar from "./ProgressBar";
import GeneratedCostInfoTable from "./GeneratedCostInfoTable";
import GeneratedSensitivitiesTable from "./GeneratedSensitivitiesTable";
import Status from "./Status";
import SchemaConstants from "../../utils/schemaConsts";

interface RouteParams {
    id: string;
    page: string;
}

enum ControlGroupPages {
    GENERAL,
    CONTROLLABILITY,
    RESOURCES,
    MESSAGES,
    ACTIVATIONS,
    "GENERATED-COST-INFO",
    "GENERATED-SENSITIVITIES"
}

const EDITING_PAGES = ["general", undefined, "controllability", "resources"]

const ControlGroupsDetails: React.FC<{}> = () => {
    const { id, page } = useParams<RouteParams>();
    const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.VIEW);
    const theme = useTheme();
    const matchesSmallDevice = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });
    const [isSaving, setIsSaving] = useState(false);
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { currentGroup, allGroups } = useAppSelector(state => state.controlGroups);
    const currentGroupIndex = (allGroups ?? []).findIndex(r => r.externalID === currentGroup?.externalID)
    const allResources = useAppSelector(fullResourcesDataSelector, (left, right) => left?.length === right?.length);
    const { allPartners: marketPartners, currentTenantMarketPartner } = useAppSelector(state => state.marketPartners);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");
    const history = useHistory();
    const classes = useStyles();
    const pollingInterval = useRef<any>(null);

    useEffect(() => {
        dispatch(resetCGState());
    }, [id, dispatch]);

    useEffect(() => {
        if (currentGroup && currentGroup.status && !stableControlGroupStates.includes(currentGroup.status) && pollingInterval.current == null) {
            pollingInterval.current = setInterval(() => {
                dispatch(fetchControlGroupStatusById(currentGroup.inventoryItemId ?? ""), 5000)
            }, 5000)
        }
        return () => {
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
        }
    }, [currentGroup, dispatch])

    const transformPageStringToPageIndex = useCallback(() => {
        if (page) {
            // @ts-ignore
            if (ControlGroupPages[page.toUpperCase()] === undefined) {
                history.replace(`/controlGroups/${id}`)
                return 0;
            }

            // @ts-ignore
            return ControlGroupPages[page.toUpperCase()];
        }
        return 0;
    }, [page, id, history]);

    useEffect(() => {
        if (pageStatus === PageStatus.EDIT)
            dispatch(setCurrentPage("EditControlGroup"));
        else if (pageStatus === PageStatus.ADD)
            dispatch(setCurrentPage("AddControlGroup"))
        else
            dispatch(setCurrentPage("ControlGroupDetails"))

    }, [dispatch, pageStatus])

    useEffect(() => {
        const buildState = async (): Promise<void> => {
            if (id === "add" && currentTenantMarketPartner !== undefined && marketPartners !== undefined) {
                dispatch(setCurrentGroup({}));
                setPageStatus(PageStatus.ADD);
            } else {
                await dispatch(buildCGState({ force: false, id }))
            }
        }
        buildState();
    }, [id, currentTenantMarketPartner, marketPartners, dispatch])

    useEffect(() => () => {
        // eslint-disable-next-line no-restricted-globals
        if (!location.pathname.includes("/controlGroups/")) {
            dispatch(resetCGState())
            dispatch(setCurrentGroup(undefined));
        }
    }, [id, dispatch])

    const forceRefresh = async (): Promise<void> => {
        try {
            setIsSaving(true)
            await dispatch(resetCGState());
            await dispatch(forceRefreshCG());
        } finally {
            setIsSaving(false)
        }
    };

    useEffect(() => {
        if (isSaving)
            history.replace(`/controlGroups/${id}`)
    }, [isSaving, history, id])

    const selectTab = (e: any): void => {
        switch (e.selected) {
            case ControlGroupPages.GENERAL:
                history.replace(`/controlGroups/${id}`);
                break;
            case ControlGroupPages.CONTROLLABILITY:
                history.replace(`/controlGroups/${id}/controllability`);
                break;
            case ControlGroupPages.RESOURCES:
                history.replace(`/controlGroups/${id}/resources`);
                break;
            case ControlGroupPages.MESSAGES:
                history.replace(`/controlGroups/${id}/messages`);
                break;
            case ControlGroupPages.ACTIVATIONS:
                history.replace(`/controlGroups/${id}/activations`);
                break;
            case ControlGroupPages["GENERATED-COST-INFO"]:
                history.replace(`/controlGroups/${id}/generated-cost-info`);
                break;
            case ControlGroupPages["GENERATED-SENSITIVITIES"]:
                history.replace(`/controlGroups/${id}/generated-sensitivities`);
                break;
            default:
                break;
        }
    };

    const createControlGroup = async (): Promise<void> => {
        if (currentGroup) {
            try {
                await dispatch(addNewControlGroup(currentGroup)).unwrap()
                history.replace(`/controlGroups/${currentGroup.externalID}`)
            } catch (e) {
                dispatch(fetchControlGroups(true))
            }
            setPageStatus(PageStatus.VIEW);
        }
    }

    const saveChanges = async (): Promise<void> => {
        if (currentGroup) {
            setIsSaving(true);
            try {
                await dispatch(updateControlGroup(currentGroup)).unwrap();
                setPageStatus(PageStatus.VIEW);
                history.replace(`/controlGroups/${currentGroup.externalID}`)
            } catch (e) {
                dispatch(fetchControlGroups(true))
            }

            setIsSaving(false);
        }
    }

    const handlePreviousClick = (): void => {
        if (currentGroupIndex > 0)
            history.push(`/controlGroups/${(allGroups ?? [])[currentGroupIndex - 1].externalID ?? ""}/${page}`);
    }

    const handleNextClick = (): void => {
        if (currentGroupIndex < (allGroups?.length ?? 0) - 1)
            history.push(`/controlGroups/${(allGroups ?? [])[currentGroupIndex + 1].externalID ?? ""}/${page}`);
    }

    const toggleEditing = async (): Promise<void> => {
        if (pageStatus) {
            // eslint-disable-next-line no-alert
            const confirm = window.confirm(t("changesWillBeReverted"));
            if (confirm) {
                setPageStatus(PageStatus.VIEW);
                await dispatch(resetCGBeforeEdit());
            }
            return;
        }
        setPageStatus(PageStatus.EDIT);
    };

    const prepareMP = useCallback(() => getMarketPartnersId(marketPartners), [marketPartners]);

    const getAllGroupsIDs = useCallback((): string[] => (allGroups ?? []).filter(g => g.inventoryItemId !== currentGroup?.inventoryItemId).map(g => g.externalID ?? ""), [allGroups, currentGroup])

    const GeneralTabSchema = CGGeneralTabSchema(prepareMP(), marketPartners?.filter(mp => areMarketPartnersSame(mp, currentTenantMarketPartner)), getAllGroupsIDs());
    const ControllabilityTabSchema = CGControllabilityTabSchema();

    const isGeneralTabValid = GeneralTabSchema.isValidSync(currentGroup);
    const isControllabilityTabValid = ControllabilityTabSchema.isValidSync(currentGroup);
    const isCRsTabValid = CGCRTableSchema().isValidSync(allResources);

    const isGroupCorrect = useCallback(() => {
        if (!currentGroup) return false;
        if (currentGroup.status == null) return false;

        if (![
            ControlGroupStatus.Unknown,
            ControlGroupStatus.Created,
            ControlGroupStatus.Valid,
            ControlGroupStatus.Updated,
            ControlGroupStatus.Confirmed,
            ControlGroupStatus.SendingFailed
        ].includes(currentGroup?.status!)) return false;
        const ControlGroupSchema = CGSchema(prepareMP(), allResources ?? [], marketPartners?.filter(mp => areMarketPartnersSame(mp, currentTenantMarketPartner)), getAllGroupsIDs())
        if (ControlGroupSchema.isValidSync(currentGroup)) {
            if (currentGroup?.status !== ControlGroupStatus.SendingFailed) {
                dispatch(setStatus(ControlGroupStatus.Confirmed))
            }
            return true;
        }

        return false;
    }, [currentGroup, prepareMP, allResources, marketPartners, currentTenantMarketPartner, dispatch, getAllGroupsIDs])

    const confirmGroup = async (): Promise<void> => {
        if (currentGroup && currentGroup.inventoryItemId) {
            setIsSaving(true)
            await dispatch(confirmGroupAction(currentGroup.inventoryItemId));
            setIsSaving(false)
        }
    }

    if (currentGroup === undefined) {
        return <LoadingComponent/>
    }

    if (currentGroup === null) {
        return <>
            Invalid Id
        </>;
    }
    // TODO: REMOVE THIS AFTER TESTING!
    const hasSuccessFullDispatch = true;
    console.log("Remove this!")
    return (
        <List disablePadding style={{
            pointerEvents: isSaving ? "none" : "all",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
            <Status pageStatus={pageStatus}/>
            <ProgressBar/>
            <DetailsViewToolbar
                disabled={!isFieldValid(GeneralTabSchema, [SchemaConstants.externalID], { externalID: currentGroup.externalID }).valid}
                onAddClick={createControlGroup}
                onCancelClick={toggleEditing}
                onSaveClick={saveChanges}
                onEditClick={EDITING_PAGES.includes(page) ? toggleEditing : undefined}
                onRefresh={forceRefresh}
                onEnhance={isGroupCorrect() ? confirmGroup : undefined}
                pageStatus={pageStatus}
                onPreviousClick={currentGroupIndex > 0 ? handlePreviousClick : undefined}
                onNextClick={currentGroupIndex < (allGroups?.length ?? 0) - 1 && currentGroupIndex !== -1 ? handleNextClick : undefined}
                isForGroup
            />
            <TabStrip tabPosition={matchesSmallDevice ? "top" : "left"} selected={transformPageStringToPageIndex()}
                      className={matchesSmallDevice ? classes.mobileScrollableTabs : classes.scrollableTabs}
                      onSelect={selectTab}>
                <TabStripTab title={<CustomTabTitle isValid={isGeneralTabValid}
                                                    title={t("control_groups_details.tabs.general")}/>}>
                    {isSaving ? <LoadingComponent/> : <GeneralTab pageStatus={pageStatus}/>}
                </TabStripTab>
                <TabStripTab title={<CustomTabTitle isValid={isControllabilityTabValid}
                                                    title={t("control_groups_details.tabs.controllability")}/>}>
                    <ControllabilitySubGrid pageStatus={pageStatus}/>
                </TabStripTab>
                {(!isAdding) &&
                    <TabStripTab title={<CustomTabTitle isValid={isCRsTabValid}
                                                        title={t("control_groups_details.tabs.controllableResources")}/>}>
                        <ControllableResourcesTable pageStatus={pageStatus}/>
                    </TabStripTab>
                }
                {(!isAdding && !isEditing && hasSuccessFullDispatch) &&
                    <TabStripTab title={<CustomTabTitle isValid
                                                        title={t("control_groups_details.tabs.connect_plus_messages")}/>}>
                        <ConnectPlusMsgsTable/>
                    </TabStripTab>
                }
                {(!isAdding && !isEditing && hasSuccessFullDispatch) &&
                    <TabStripTab title={<CustomTabTitle isValid
                                                        title={t("control_groups_details.tabs.activations")}/>}>
                        <ActivationsTable/>
                    </TabStripTab>
                }
                {(!isAdding && !isEditing && hasSuccessFullDispatch) &&
                    <TabStripTab title={<CustomTabTitle isValid
                                                        title={t("control_groups_details.tabs.generated_cost_info")}/>}>
                        <GeneratedCostInfoTable/>
                    </TabStripTab>
                }
                {(!isAdding && !isEditing && hasSuccessFullDispatch) &&
                    <TabStripTab title={<CustomTabTitle isValid
                                                        title={t("control_groups_details.tabs.generated_sensitivities")}/>}>
                        <GeneratedSensitivitiesTable/>
                    </TabStripTab>
                }
            </TabStrip>
        </List>
    )
}

export default ControlGroupsDetails
