import { Field, isFieldValid, LoadingComponent, PageStatus } from "ndr-designsystem";
import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
    setAdditionalAffectedGridOperators,
    setAffectedGridOperators,
    setExternalID,
    setName,
    swapAffectedGridOperators,
    updateNewAdditionalAffectedGridOperator,
    updateNewAffectedGridOperator
} from "./store/controlGroupsSlice";
import SchemaConstants from "../../utils/schemaConsts";
import GeneralGridOperatorsTable from "../../Components/GeneralGridOperatorsTable";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CGGeneralTabSchema } from "../../utils/inputChecking";
import { areMarketPartnersSame, getMarketPartnersId } from "../../utils";

const GeneralTab = ({ pageStatus }: { pageStatus: PageStatus }): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { currentGroup, allGroups } = useAppSelector(state => state.controlGroups);
    const { allPartners: marketPartners, currentTenantMarketPartner } = useAppSelector(state => state.marketPartners);
    const { t } = useTranslation("tables");
    const dispatch = useAppDispatch();
    const prepareMP = useCallback(() => getMarketPartnersId(marketPartners), [marketPartners]);
    const getAllGroupsIDs = useCallback((): string[] => (allGroups ?? []).filter(g => g.inventoryItemId !== currentGroup?.inventoryItemId).map(g => g.externalID ?? ""), [allGroups, currentGroup])

    const GeneralTabSchema = CGGeneralTabSchema(prepareMP(), marketPartners?.filter(mp => areMarketPartnersSame(mp, currentTenantMarketPartner)), getAllGroupsIDs());
    if (currentGroup === undefined) {
        return <LoadingComponent/>
    }

    if (currentGroup === null) {
        return <>
            Invalid Id
        </>;
    }

    return (
        <>
            <Field
                inputType="controlledText"
                onValueChange={val => dispatch(setExternalID(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={externalID => isFieldValid(GeneralTabSchema, [SchemaConstants.externalID], { externalID })}
                defaultValue={currentGroup.externalID}
                title={t("control_groups_details.externalID")}
            />
            <Field
                inputType="controlledText"
                onValueChange={val => dispatch(setName(val))}
                isEditing={isEditing || isAdding}
                valueCheckFunction={name => isFieldValid(GeneralTabSchema, [SchemaConstants.name], { name })}
                defaultValue={currentGroup.name}
                title={t("control_groups_details.name")}
            />
            <GeneralGridOperatorsTable
                showLabel
                firstElementCurrentTenantMarketPartner
                addNewMarketPartnerData={{ type: "group", fieldPath: "affectedGridOperators" }}
                showIndex={false}
                validationResult={isFieldValid(GeneralTabSchema, [SchemaConstants.affectedGridOperators], { affectedGridOperators: currentGroup.affectedGridOperators })}
                title={t("controllable_resources_details.affectedGridOperators")}
                description={t("controllable_resources_details.affectedGridOperatorsDescription")}
                pageStatus={pageStatus}
                newElementPosition="bottom"
                elements={currentGroup.affectedGridOperators}
                actions={{
                    setGridOperators: setAffectedGridOperators,
                    updateNewGridOperator: updateNewAffectedGridOperator,
                    swapGridOperators: swapAffectedGridOperators
                }}
            />
            <GeneralGridOperatorsTable
                showIndex={false}
                showLabel={false}
                addNewMarketPartnerData={{ type: "group", fieldPath: "additionalGridAffectedOperators" }}
                validationResult={isFieldValid(GeneralTabSchema, [SchemaConstants.additionalAffectedGridOperators], { additionalAffectedGridOperators: currentGroup.additionalAffectedGridOperators })}
                title={t("controllable_resources_details.additionalAffectedGridOperators")}
                description={t("controllable_resources_details.additionalAffectedGridOperatorsDescription")}
                pageStatus={pageStatus}
                elements={currentGroup.additionalAffectedGridOperators}
                actions={{
                    setGridOperators: setAdditionalAffectedGridOperators,
                    updateNewGridOperator: updateNewAdditionalAffectedGridOperator
                }}
            />
        </>
    )
}

export default GeneralTab
