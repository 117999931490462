import { Box, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PageStatus } from "ndr-designsystem";
import { useAppSelector } from "../../app/hooks";
import useStyles from "./styles";
import ControlGroupStatus from "../../api/fixed/ControlGroup/ControlGroupStatus";
import SentIndicator from "./list/columns/SentIndicator";

const Status = ({ pageStatus }: { pageStatus: PageStatus }): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { currentGroup } = useAppSelector(state => state.controlGroups)
    const classes = useStyles();
    const { t } = useTranslation("tables");
    if (!currentGroup) return <></>
    const translatedStatusName = !isEditing && currentGroup.status && currentGroup.status.length ? t(`control_groups_details.states.${currentGroup.status}`) : null;

    return <>
        {currentGroup.status === ControlGroupStatus.Accepted &&
            <Box className={classes.acceptedStyle} width="100%" height={40}>
                <Box display="flex"
                     justifyContent="space-between" alignItems="center" px={2} py={1}>
                    <Typography sx={{ width: "100%" }}>
                        {t("controllable_resources_details.status")}: {translatedStatusName}
                    </Typography>
                    <SentIndicator controlGroup={currentGroup} alwaysShowText/>
                </Box>
            </Box>
        }
        {currentGroup.status === ControlGroupStatus.Rejected &&
            <Box className={classes.rejectedStyle} width="100%" height={40}>
                <Box display="flex"
                     justifyContent="space-between" alignItems="center" px={2} py={1}>
                    <Typography sx={{ width: "100%" }}>
                        {t("controllable_resources_details.status")}: {translatedStatusName}
                    </Typography>
                    <SentIndicator controlGroup={currentGroup} alwaysShowText/>
                </Box>
            </Box>
        }
        {(currentGroup.status !== ControlGroupStatus.Accepted &&
                currentGroup.status !== ControlGroupStatus.Rejected &&
                currentGroup.hasSuccessfulDispatch &&
                !isAdding && !!translatedStatusName) &&
            <Box width="100%" className={classes.hasSuccessfulDispatchStyle} height={40}>
                <Box display="flex"
                     justifyContent="space-between" alignItems="center" px={2} py={1}>
                    <Typography sx={{ width: "100%" }}>
                        {t("controllable_resources_details.status")}: {translatedStatusName}
                    </Typography>
                    <SentIndicator controlGroup={currentGroup} alwaysShowText/>
                </Box>
            </Box>
        }
        {(currentGroup.status !== ControlGroupStatus.Accepted &&
                currentGroup.status !== ControlGroupStatus.Rejected &&
                !currentGroup.hasSuccessfulDispatch &&
                !isAdding && !!translatedStatusName) &&
            <Box width="100%" height={40}>
                <Box display="flex"
                     justifyContent="space-between" alignItems="center" px={2} py={1}>
                    <Typography sx={{ width: "100%" }}>
                        {t("controllable_resources_details.status")}: {translatedStatusName}
                    </Typography>
                    <SentIndicator controlGroup={currentGroup} alwaysShowText/>
                </Box>
            </Box>
        }
    </>
}

export default Status
