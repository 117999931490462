import { createAsyncThunk } from "@reduxjs/toolkit";
import { createDateTime, GridDataState } from "ndr-designsystem";
import { RootState } from "../../../app/store";
import Api from "../../../api/Api";
import { removeNulls } from "../../../utils";
import { TaskDto } from "../../../api/dtos/Task/TaskDto";
import { setAllTasks, setExpandedItemId, setTotalItems } from "./store";

export const fetchTasks = createAsyncThunk(
    "tasks/fetch",
    async (dataState: GridDataState, { getState, dispatch }) => {
        let response: TaskDto[] | undefined;
        const state = getState() as RootState;
        if (dataState.take === undefined || dataState.skip === undefined) return;

        response = state.tasks.allTasks;
        const statusFilter = (dataState.filter?.filters.find((f: any) => f.field === "status") as any)?.value;
        const typeFilter = (dataState.filter?.filters.find((f: any) => f.field === "type") as any)?.value;
        const startedAtFilter = createDateTime((dataState.filter?.filters.find((f: any) => f.field === "startedAt") as any)?.value).toISO();
        const startedAtFilterOperator = (dataState.filter?.filters.find((f: any) => f.field === "startedAt") as any)?.operator
        const completedAtFilter = createDateTime((dataState.filter?.filters.find((f: any) => f.field === "completedAt") as any)?.value).toISO();
        const completedAtFilterOperator = (dataState.filter?.filters.find((f: any) => f.field === "completedAt") as any)?.operator

        const fullResponse = (await Api.fetchTasks(dataState.skip / dataState.take, dataState.take, statusFilter, typeFilter, startedAtFilter, startedAtFilterOperator, completedAtFilter, completedAtFilterOperator)).data
        const allTasks = fullResponse.content;
        response = allTasks.map(data => removeNulls(data));
        dispatch(setTotalItems(fullResponse.totalElements))
        dispatch(setAllTasks(response))
    }
)
export const resetTasksState = createAsyncThunk(
    "tasks/resetState",
    async (_: never, { dispatch }) => {
        await dispatch(setAllTasks(undefined))
        await dispatch(setTotalItems(undefined));
        await dispatch(setExpandedItemId(undefined))
        await dispatch(setTotalItems(undefined))
    })

