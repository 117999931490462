import React, { ReactElement } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Box } from "@mui/material";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import useStyles from "./styles";
import TimeSeriesBreadcrumb from "./TimeSeriesBreadcrumb";
import ControllableResourcePageBreadcrumb from "./ControllableResourcePageBreadcrumb";
import ControlGroupPageBreadcrumb from "./ControlGroupPageBreadcrumb";
import TechnicalResourcePageBreadcrumb from "./TechnicalResourcePageBreadcrumb";
import ListBreadcrumb from "./ListBreadcrumb";
import NullBreadcrumb from "./NullBreadcrumb";

const nullBreadcrumbs: string[] = []

const routes = [
    ...nullBreadcrumbs.map(b => ({ path: b, breadcrumb: NullBreadcrumb })),
    { path: "/controllable/:inventoryId/activations/:seriesId", breadcrumb: TimeSeriesBreadcrumb },
    { path: "/controllable/:controllableId/network-constraints/:seriesId", breadcrumb: TimeSeriesBreadcrumb },
    { path: "/controllable/:controllableId/unavailability/:seriesId", breadcrumb: TimeSeriesBreadcrumb },
    { path: "/controllable/:controllableId/planning-data/:seriesId", breadcrumb: TimeSeriesBreadcrumb },
    {
        path: "/controllable/:controllableId/resources/:technicalId/downtime/:seriesId",
        breadcrumb: TimeSeriesBreadcrumb
    },
    { path: "/controlGroups/:inventoryId/activations/:seriesId", breadcrumb: TimeSeriesBreadcrumb },
    { path: "/controllable/:id/generated-cost-info/:dispatchId", breadcrumb: TimeSeriesBreadcrumb },
    { path: "/controllable/:id/generated-sensitivities/:dispatchId", breadcrumb: TimeSeriesBreadcrumb },
    { path: "/controlGroups/:id/generated-cost-info/:dispatchId", breadcrumb: TimeSeriesBreadcrumb },
    { path: "/controlGroups/:id/generated-sensitivities/:dispatchId", breadcrumb: TimeSeriesBreadcrumb },
    { path: "/controllable/:id/:page", breadcrumb: ControllableResourcePageBreadcrumb },
    { path: "/controlGroups/:id/:page", breadcrumb: ControlGroupPageBreadcrumb },
    { path: "/controllable/:controllableId/resources/:technicalId/:page", breadcrumb: TechnicalResourcePageBreadcrumb },
    { path: "/controllable", breadcrumb: ListBreadcrumb },
    { path: "/controlGroups", breadcrumb: ListBreadcrumb },
    { path: "/market-partners", breadcrumb: ListBreadcrumb },
    { path: "/messages", breadcrumb: ListBreadcrumb },
    { path: "/dashboard", breadcrumb: ListBreadcrumb },
    { path: "/tasks", breadcrumb: ListBreadcrumb },
    { path: "/avat-errors", breadcrumb: ListBreadcrumb },
    { path: "/", breadcrumb: ListBreadcrumb }
]

const SimpleBreadcrumbs = ({ breadcrumbs }: { breadcrumbs: any[] }): ReactElement => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const filteredBreadcrumbs = breadcrumbs.filter(b => !nullBreadcrumbs.includes(b.match.path))

    return (
        <Box display="flex" flexDirection="row" marginTop={1} marginBottom={1}>
            {filteredBreadcrumbs.length > 0 &&
                <Typography>
                    {t('breadcrumbs_text')}&nbsp;
                </Typography>
            }
            <Breadcrumbs aria-label="breadcrumb">
                {filteredBreadcrumbs.map((b, index) => {
                    if (index === filteredBreadcrumbs.length - 1) {
                        return <Typography color="textPrimary" className={classes.lastItemStyle} key={b.match.url}>
                            {b.breadcrumb}
                        </Typography>
                    }
                    return <Link className={classes.normalItemStyle} key={b.match.url}
                                 to={b.match.url}>{b.breadcrumb}</Link>
                })}
            </Breadcrumbs>
        </Box>
    );
}

export default withBreadcrumbs(routes)(SimpleBreadcrumbs)
