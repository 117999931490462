import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ArrowBack, ArrowForward, Cached } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { CustomButton } from "ndr-designsystem";
import useStyles from "./styles";
import { FieldWithGridProps, PageStatus } from "../utils/types";

interface Props extends FieldWithGridProps {
    disabled: boolean,
    onAddClick?: () => void
    onCancelClick?: () => void
    onSaveClick?: () => void
    onEditClick?: () => void
    onRefresh?: () => void
    onEnhance?: () => void
    isForGroup: boolean,
    onNextClick?: () => void
    onPreviousClick?: () => void
}

const DetailsViewToolbar = ({
                                pageStatus,
                                disabled,
                                onAddClick,
                                onSaveClick,
                                onCancelClick,
                                onEditClick,
                                onRefresh,
                                onEnhance,
                                isForGroup,
                                onNextClick, onPreviousClick
                            }: Props): ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation("tables");
    const previousMessage = t(`toolbar_buttons.${onPreviousClick !== undefined ? "" : "no_"}previous_${isForGroup ? "group" : "resource"}`)
    const nextMessage = t(`toolbar_buttons.${onNextClick !== undefined ? "" : "no_"}next_${isForGroup ? "group" : "resource"}`)

    return (
        <div className={classes.titleContainer}>
            <div className={`${classes.addNewRightButton} ${classes.addNewTitleButton}`}>
                <div>
                    {(onRefresh && pageStatus === PageStatus.VIEW) &&
                        <IconButton color="primary" component="span" onClick={onRefresh}>
                            <Cached color="primary"/>
                        </IconButton>
                    }
                </div>
                {pageStatus === PageStatus.ADD &&
                    <>
                        <div/>
                        {onAddClick &&
                            <CustomButton title={t("toolbar_buttons.add")} disabled={disabled}
                                          onClick={onAddClick}/>
                        }
                    </>
                }
                {pageStatus === PageStatus.EDIT &&
                    <>
                        <div/>
                        <div>
                            {onCancelClick &&
                                <CustomButton title={t("toolbar_buttons.cancel")}
                                              onClick={onCancelClick}/>
                            }
                            {onSaveClick &&
                                <CustomButton title={t("toolbar_buttons.save")} disabled={disabled}
                                              onClick={onSaveClick}/>
                            }
                        </div>
                    </>
                }
                {pageStatus === PageStatus.VIEW &&
                    <div>
                        {onEnhance &&
                            <CustomButton
                                title={t(`toolbar_buttons.${isForGroup ? "confirmGroup" : "approveEnhancedData"}`)}
                                onClick={onEnhance}/>
                        }
                        {onEditClick &&
                            <CustomButton title={t("toolbar_buttons.edit")} onClick={onEditClick}/>
                        }
                        <Tooltip
                            enterDelay={250}
                            title={previousMessage}
                        >
                            <span>
                                <IconButton color="primary" onClick={onPreviousClick} disabled={!onPreviousClick}>
                                    <ArrowBack/>
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip
                            title={nextMessage}
                        >
                            <span>
                                <IconButton color="primary" component="button" onClick={onNextClick}
                                            disabled={onNextClick === undefined}>
                                    <ArrowForward/>
                                </IconButton>
                            </span>
                        </Tooltip>

                    </div>
                }
            </div>
        </div>
    );
};

DetailsViewToolbar.defaultProps = {
    onRefresh: undefined,
    onEnhance: undefined,
    onEditClick: undefined,
    onNextClick: undefined,
    onPreviousClick: undefined,
    onSaveClick: undefined,
    onAddClick: undefined,
    onCancelClick: undefined
};

export default DetailsViewToolbar;