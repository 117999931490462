import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ControllableResource } from "../../../api/fixed/ControllableResource/ControllableResource";
import { Unit } from "../../../api/fixed/Unit";
import { EnergySource, technicalParametersAllowedValues } from "../../../api/fixed/ControllableResource/EnergySource";
import { CompensationType } from "../../../api/fixed/ControllableResource/CompensationType";
import { Fixation } from "../../../api/fixed/Fixation";
import { ControllabilityType } from "../../../api/fixed/ControllableResource/ControllabilityType";
import { ActivationType } from "../../../api/fixed/ControllableResource/ActivationType";
import { AccountingModel } from "../../../api/fixed/ControllableResource/AccountingModel";
import { ControlArea } from "../../../api/fixed/ControllableResource/ControlArea";
import { GradientUnit } from "../../../api/fixed/ControllableResource/GrdientUnit";
import ControllableResourceStatus from "../../../api/fixed/ControllableResource/ControllableResourceStatus";

export interface ControllableResourcesState {
    allResources: ControllableResource[] | undefined;
    currentResource: ControllableResource | null | undefined;
}

const initialState: ControllableResourcesState = {
    allResources: undefined,
    currentResource: undefined,
};

export const controllableResourcesSlice = createSlice({
    name: 'controllableResources',
    initialState,
    reducers: {
        setCurrentResource: (state, action: PayloadAction<ControllableResource | null | undefined>) => {
            state.currentResource = action.payload;
        },
        addResourceToArray: (state, action: PayloadAction<ControllableResource>) => {
            if (state.allResources) {
                state.allResources.push(action.payload);
            } else {
                state.allResources = [action.payload]
            }
            state.currentResource = action.payload
        },
        overwriteResourceInArray: (state, action: PayloadAction<ControllableResource>) => {
            if (state.allResources) {
                const idx = state.allResources.findIndex(r => r.inventoryItemId === action.payload.inventoryItemId);
                state.allResources[idx] = action.payload;
            }
        },
        incrementRevision: (state) => {
            if (state.currentResource) {
                if (state.currentResource.revision != null) {
                    state.currentResource.revision += 1;
                } else {
                    state.currentResource.revision = 0;
                }
                if (state.allResources) {
                    const idx = state.allResources.findIndex(r => r.inventoryItemId === state.currentResource?.inventoryItemId);
                    state.allResources[idx] = state.currentResource;
                }
            }
        },
        swapAffectedGridOperators: (state, action: PayloadAction<{ startIndex: number, endIndex: number }>) => {
            const { startIndex, endIndex } = action.payload;
            if (state.currentResource && state.currentResource.affectedGridOperatorIds) {
                const item = state.currentResource.affectedGridOperatorIds[startIndex];
                state.currentResource.affectedGridOperatorIds[startIndex] = state.currentResource.affectedGridOperatorIds[endIndex];
                state.currentResource.affectedGridOperatorIds[endIndex] = item;
            }
        },
        swapAdditionalAffectedGridOperators: (state, action: PayloadAction<{ startIndex: number, endIndex: number }>) => {
            const { startIndex, endIndex } = action.payload;
            if (state.currentResource && state.currentResource.additionalAffectedGridOperatorIds) {
                const item = `${state.currentResource.additionalAffectedGridOperatorIds[startIndex]}`;
                state.currentResource.additionalAffectedGridOperatorIds[startIndex] = state.currentResource.additionalAffectedGridOperatorIds[endIndex];
                state.currentResource.additionalAffectedGridOperatorIds[endIndex] = item;
            }
        },
        setAllResources: (state, action: PayloadAction<ControllableResource[] | undefined>) => {
            state.allResources = action.payload;
        },
        resetCurrentResource: (state, action: PayloadAction<string>) => {
            state.currentResource = {
                ...state.allResources?.find(r => r.inventoryItemId === action.payload || r.externalID === action.payload)
            }
        },
        updateNewLevel: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource && state.currentResource.levels) {
                state.currentResource.levels[0] = action.payload;
            }
        },
        updateNewAffectedGridOperator: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource && state.currentResource.affectedGridOperatorIds && action.payload) {
                state.currentResource.affectedGridOperatorIds[state.currentResource.affectedGridOperatorIds.length - 1] = action.payload;
            }
        },
        updateNewAdditionalAffectedGridOperator: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource && state.currentResource.additionalAffectedGridOperatorIds && action.payload) {
                state.currentResource.additionalAffectedGridOperatorIds[0] = action.payload;
            }
        },
        setAffectedGridOperatorAtIndex: (state, action: PayloadAction<{ index: number, value: string }>) => {
            const { index, value } = action.payload;
            if (state.currentResource && state.currentResource.affectedGridOperatorIds) {
                state.currentResource.affectedGridOperatorIds[index] = value;
            }
        },
        setAdditionalAffectedGridOperatorAtIndex: (state, action: PayloadAction<{ index: number, value: string }>) => {
            const { index, value } = action.payload;
            if (state.currentResource && state.currentResource.additionalAffectedGridOperatorIds) {
                state.currentResource.additionalAffectedGridOperatorIds[index] = value;
            }
        },
        setAffectedGridOperators: (state, action: PayloadAction<string[] | undefined>) => {
            if (state.currentResource)
                state.currentResource.affectedGridOperatorIds = action.payload;
        },
        setAdditionalAffectedGridOperators: (state, action: PayloadAction<string[] | undefined>) => {
            if (state.currentResource)
                state.currentResource.additionalAffectedGridOperatorIds = action.payload;
        },
        setOperationsManager: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource)
                state.currentResource.operationsManagerId = action.payload;
        },
        setEnergySource: (state, action: PayloadAction<EnergySource | undefined>) => {
            if (state.currentResource) {
                if (technicalParametersAllowedValues.includes(action.payload?.code ?? "") &&
                    !technicalParametersAllowedValues.includes(state.currentResource.energySource?.code ?? "")) {
                    state.currentResource.minPower = undefined;
                    state.currentResource.minOperatingTime = undefined;
                    state.currentResource.minDownTime = undefined;
                    state.currentResource.rampupTimeCold = undefined;
                    state.currentResource.rampupTimeHot = undefined;
                    state.currentResource.startupTimeCold = undefined;
                    state.currentResource.startupTimeHot = undefined;
                    state.currentResource.shutdownTime = undefined;
                    state.currentResource.ratedCapacityGradient = undefined;
                    state.currentResource.ratedCapacityGradientUnit = undefined;
                    state.currentResource.ratedCapacityGradientBaseSize = undefined;
                    state.currentResource.minCapacityGradient = undefined;
                    state.currentResource.minCapacityGradientUnit = undefined;
                    state.currentResource.minCapacityGradientBaseSize = undefined;
                }
                state.currentResource.energySource = action.payload;
            }
        },
        setCompensationType: (state, action: PayloadAction<CompensationType | undefined>) => {
            if (state.currentResource)
                state.currentResource.compensationType = action.payload;
        },
        setIsTolerationCase: (state, action: PayloadAction<boolean | undefined>) => {
            if (state.currentResource) {
                state.currentResource.isTolerationCase = action.payload;
                if (action.payload) {
                    state.currentResource.processingTimeEiv = undefined;
                    state.currentResource.activationType = undefined;
                }
            }
        },
        setName: (state, action: PayloadAction<string>) => {
            if (state.currentResource) {
                state.currentResource.name = action.payload;
            }
        },
        setExternalID: (state, action: PayloadAction<string>) => {
            if (state.currentResource) {
                state.currentResource.externalID = action.payload;
            }
        },
        setFixation: (state, action: PayloadAction<Fixation | undefined>) => {
            if (state.currentResource)
                state.currentResource.fixation = action.payload;
        },
        setControllabilityType: (state, action: PayloadAction<ControllabilityType | undefined>) => {
            if (state.currentResource) {
                state.currentResource.controllabilityType = action.payload;
                state.currentResource.stepLength = undefined;
                state.currentResource.minSteps = undefined;
                state.currentResource.maxSteps = undefined;
                state.currentResource.levels = undefined;
            }
        },
        setStatus: (state, action: PayloadAction<ControllableResourceStatus | undefined>) => {
            if (state.currentResource)
                state.currentResource.status = action.payload;
        },
        setUnit: (state, action: PayloadAction<Unit | undefined>) => {
            if (state.currentResource) {
                state.currentResource.unit = action.payload;
                state.currentResource.stepLength = undefined;
                state.currentResource.minSteps = undefined;
                state.currentResource.maxSteps = undefined;
            }
        },
        setLevels: (state, action: PayloadAction<number[] | undefined>) => {
            if (state.currentResource)
                state.currentResource.levels = action.payload;
        },
        swapLevels: (state, action: PayloadAction<{ startIndex: number, endIndex: number }>) => {
            const { startIndex, endIndex } = action.payload;
            if (state.currentResource && state.currentResource.levels) {
                const startItem = state.currentResource.levels[startIndex];
                state.currentResource.levels[startIndex] = state.currentResource.levels[endIndex]
                state.currentResource.levels[endIndex] = startItem;
            }
        },
        setStepLength: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.stepLength = action.payload;
        },
        setMinSteps: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.minSteps = action.payload;
        },
        setMinPower: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.minPower = action.payload;
        },
        setMinOperatingTime: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.minOperatingTime = action.payload;
        },
        setRampUpTimeCold: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.rampupTimeCold = action.payload;
        },
        setRampUpTimeHot: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.rampupTimeHot = action.payload;
        },
        setStartUpTimeCold: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.startupTimeCold = action.payload;
        },
        setStartUpTimeHot: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.startupTimeHot = action.payload;
        },
        setMaxSteps: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.maxSteps = action.payload;
        },
        setShutdownTime: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.shutdownTime = action.payload;
        },
        setMinDownTime: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.minDownTime = action.payload;
        },
        setActivationType: (state, action: PayloadAction<ActivationType | undefined>) => {
            if (state.currentResource)
                state.currentResource.activationType = action.payload;
        },
        setAccountingModel: (state, action: PayloadAction<AccountingModel | undefined>) => {
            if (state.currentResource)
                state.currentResource.accountingModel = action.payload;
        },
        setControlArea: (state, action: PayloadAction<ControlArea | undefined>) => {
            if (state.currentResource)
                state.currentResource.controlArea = action.payload;
        },
        setRatedCapacityGradient: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.ratedCapacityGradient = action.payload;
        },
        setRatedCapacityGradientUnit: (state, action: PayloadAction<GradientUnit | undefined>) => {
            if (state.currentResource) {
                state.currentResource.ratedCapacityGradientUnit = action.payload;
                if (action.payload?.code !== "Z02") {
                    state.currentResource.ratedCapacityGradientBaseSize = undefined;
                }
            }
        },
        setRatedCapacityGradientBaseSize: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.ratedCapacityGradientBaseSize = action.payload;
        },
        setMinCapacityGradient: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.minCapacityGradient = action.payload;
        },
        setMinCapacityGradientUnit: (state, action: PayloadAction<GradientUnit | undefined>) => {
            if (state.currentResource) {
                state.currentResource.minCapacityGradientUnit = action.payload;
                if (action.payload?.code !== "Z02") {
                    state.currentResource.minCapacityGradientBaseSize = undefined;
                }
            }
        },
        setMinCapacityGradientBaseSize: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource)
                state.currentResource.minCapacityGradientBaseSize = action.payload;
        },
        setProcessingTimeEiv: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.processingTimeEiv = action.payload;
                if (Number.isNaN(action.payload)) state.currentResource.processingTimeEiv = undefined
            }
        },
        setTechnicalResourcesIds: (state, action: PayloadAction<string[]>) => {
            if (state.currentResource) {
                state.currentResource.technicalResourceIds = action.payload;
            }
            if(state.currentResource && state.allResources) {
                const idx = state.allResources.findIndex(r => r.inventoryItemId === state.currentResource?.inventoryItemId);
                if(idx >= 0)
                    state.allResources[idx] = state.currentResource;
            }
        },
        updateStatusInArray: (state, action: PayloadAction<ControllableResourceStatus>) => {
            if (state.currentResource && state.allResources) {
                state.currentResource.status = action.payload;
                const idx = state.allResources.findIndex(r => r.inventoryItemId === state.currentResource?.inventoryItemId);
                state.allResources[idx] = state.currentResource;
            }
        }
    }
});

export const {
    setCurrentResource,
    setAdditionalAffectedGridOperators,
    setAffectedGridOperators,
    setUnit,
    setName,
    setCompensationType,
    setControllabilityType,
    setFixation,
    setIsTolerationCase,
    setOperationsManager,
    setLevels,
    setEnergySource,
    resetCurrentResource,
    setAllResources,
    setMaxSteps,
    setMinSteps,
    setStepLength,
    setActivationType,
    setAccountingModel,
    setProcessingTimeEiv,
    setControlArea,
    setMinPower,
    setMinOperatingTime,
    setMinDownTime,
    setRampUpTimeCold,
    setRampUpTimeHot,
    setStartUpTimeCold,
    setStartUpTimeHot,
    setShutdownTime,
    setRatedCapacityGradient,
    setRatedCapacityGradientUnit,
    setRatedCapacityGradientBaseSize,
    setMinCapacityGradient,
    setMinCapacityGradientBaseSize,
    setMinCapacityGradientUnit,
    setStatus,
    updateNewAffectedGridOperator,
    updateNewAdditionalAffectedGridOperator,
    updateNewLevel,
    setExternalID,
    addResourceToArray,
    swapAdditionalAffectedGridOperators,
    swapAffectedGridOperators,
    setAdditionalAffectedGridOperatorAtIndex,
    setAffectedGridOperatorAtIndex,
    overwriteResourceInArray,
    incrementRevision,
    swapLevels,
    updateStatusInArray,
    setTechnicalResourcesIds
} = controllableResourcesSlice.actions;

export default controllableResourcesSlice.reducer;
