import { DateTime } from "luxon";
import TaskType from "./TaskType";
import TaskStatus from "./TaskStatus";
import { TaskError } from "./TaskError";

export default interface Task {
    type: TaskType,
    primaryTaskId: string
    secondaryTaskId: string
    startedAt: DateTime,
    completedAt?: DateTime,
    status: TaskStatus,
    errors: TaskError[]
}

export const keys = ["type", "primaryTaskId", "secondaryTaskId", "startedAt", "completedAt", "status", "errors", "tenantId", "notifyOnError", "notifyOnSuccess"]
