import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteCookie } from "../utils";
import { ArrayFieldPaths, SimpleFieldPaths } from "../Components/props";
import { fetchControlGroups } from "./ControlGroups/store/thunks";
import { fetchControllableResources } from "./controllableResources/store/thunks";
import { MarketPartner } from "../api/fixed/MarketPartner";
import SensitivityDto from "../api/dtos/Sensitivity/SensitivityDto";
import CostInfoDto from "../api/dtos/CostInfo/CostInfo";

interface AddNewCommon {
    show: boolean
    id: string,
}

type AddNewSimplePlanningDataData = | {
    addType: "sensitivity"
    data: SensitivityDto | undefined
}

type AddNewCostInfo = | {
    data: CostInfoDto | undefined
}

type AddNewSimplePlanningData = AddNewCommon & AddNewSimplePlanningDataData
type AddNewCostInfoPayload = AddNewCommon & AddNewCostInfo

interface CommonProps {
    show: boolean,
    data: MarketPartner | undefined
}

type AddNewMarketPartner = {
    type: "control" | "group" | "technical" | "technicalTranche" | undefined
    fieldPath: SimpleFieldPaths | undefined
    elementIndex?: never
} | {
    type: "control" | "group" | "technical" | "technicalTranche" | undefined
    fieldPath: ArrayFieldPaths | undefined
    elementIndex: number
}

type AddNewMarketPartnerData = CommonProps & AddNewMarketPartner

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>
type FullfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>

export interface IAppState {
    currentPage: string | null,
    tenantAccessDenied: boolean,
    notificationAlertMessage: string | undefined,
    messageErrors: string[],
    addNewMarketPartner: AddNewMarketPartnerData,
    addNewSimplePlanningData: AddNewSimplePlanningData
    addNewCostInfo: AddNewCostInfoPayload
    lastActionResult: {
        status: boolean | undefined
        message: string | undefined
    } | undefined
    headerHeight: number,
    pageNotFound?: string,
}

const initialState: IAppState = {
    currentPage: "Dashboard",
    tenantAccessDenied: false,
    notificationAlertMessage: undefined,
    messageErrors: [],
    addNewMarketPartner: {
        show: false,
        type: "control",
        fieldPath: "affectedGridOperators",
        elementIndex: 0,
        data: undefined
    },
    addNewSimplePlanningData: {
        show: false,
        id: "",
        data: undefined,
        addType: "sensitivity"
    },
    addNewCostInfo: {
        id: "",
        show: false,
        data: undefined
    },
    lastActionResult: undefined,
    headerHeight: 0,
    pageNotFound: undefined,
};

export const appStateSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setHeaderHeight: (state, action: PayloadAction<number>) => {
            state.headerHeight = action.payload;
        },
        setLastActionResult: (state, action: PayloadAction<{ message: string, status: boolean } | undefined>) => {
            state.lastActionResult = action.payload;
        },
        setMessageErrors: (state, action: PayloadAction<string[]>) => {
            state.messageErrors = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<string>) => {
            state.currentPage = action.payload
        },
        setAddNewMarketPartnerData: (state, action: PayloadAction<AddNewMarketPartnerData>) => {
            state.addNewMarketPartner = action.payload;
        },
        setAddNewCostInfo: (state, action: PayloadAction<AddNewCostInfoPayload>) => {
            state.addNewCostInfo = action.payload;
        },
        setAddNewSimplePlanningData: (state, action: PayloadAction<AddNewSimplePlanningData>) => {
            state.addNewSimplePlanningData = action.payload;
        },
        setTenantAccessDenied: (state, action: PayloadAction<boolean>) => {
            state.tenantAccessDenied = action.payload;
        },
        setNotificationAlertMessage: (state, action: PayloadAction<string | undefined>) => {
            state.notificationAlertMessage = action.payload;
        },
        setPageNotFound: (state, action: PayloadAction<undefined>) => {
            state.pageNotFound = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher<RejectedAction>(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    const error = action.error as Error;
                    state.lastActionResult = {
                        status: false,
                        message: undefined
                    };

                    const backendErrorMessage = action.payload as { detail: string }

                    if (backendErrorMessage && backendErrorMessage.detail) {
                        state.lastActionResult = {
                            status: false,
                            message: backendErrorMessage.detail
                        }
                    }

                    if (error.message === "Request failed with status code 403" ||
                        error.message === "Request failed with status code 401") {
                        deleteCookie("token");
                    }

                    if (error.message === "Request failed with status code 404") {
                        if (action.type.startsWith("unavailability"))
                            state.pageNotFound = "unavailability";
                        else if (action.type.startsWith("planningData"))
                            state.pageNotFound = "planningData";
                        else if (action.type.startsWith("networkConstraints"))
                            state.pageNotFound = "networkConstraints";
                        else if (action.type.startsWith("downtime"))
                            state.pageNotFound = "downtime";
                        else if (action.type.startsWith("activations"))
                            state.pageNotFound = "activations";
                        else if (action.type.startsWith("technicalResources"))
                            state.pageNotFound = "technicalResources";
                        else if (action.type.startsWith("sensitivity"))
                            state.pageNotFound = "sensitivity";
                        else if (action.type.startsWith("controllableResource"))
                            state.pageNotFound = "controllableResource";
                        else if (action.type.startsWith("controlGroups"))
                            state.pageNotFound = "controlGroups";
                    }
                }
            )
            .addMatcher<FullfilledAction>(
                (action) => action.type.endsWith('_n/fulfilled'),
                (state) => {
                    state.lastActionResult = {
                        status: true,
                        message: undefined
                    };
                }
            )
    }
})

export const fetchAllControlGroupsAndResources = createAsyncThunk(
    "appState/fetchAllControlGroupsAndResources",
    async ({
               force,
               fetchGroups,
               fetchResources
           }: { force: boolean, fetchGroups: boolean, fetchResources: boolean }, { dispatch, rejectWithValue }) => {
        try {
            if (fetchGroups) await dispatch(fetchControlGroups(force))
            if (fetchResources) await dispatch(fetchControllableResources(force))
            return true;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const {
    setCurrentPage,
    setAddNewMarketPartnerData,
    setTenantAccessDenied,
    setNotificationAlertMessage,
    setAddNewSimplePlanningData,
    setMessageErrors,
    setLastActionResult,
    setHeaderHeight,
    setPageNotFound,
    setAddNewCostInfo
} = appStateSlice.actions;
export default appStateSlice.reducer
