/**
 *
 * @export
 * @interface EnergySource
 */
export interface EnergySource {
    /**
     *
     * @type {string}
     * @memberof EnergySource
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof EnergySource
     */
    name?: string;
  }
  
  export const allowedCodes = [
    "B01",
    "B02",
    "B03",
    "B04",
    "B05",
    "B06",
    "B09",
    "B10",
    "B11",
    "B12",
    "B14",
    "B15",
    "B16",
    "B17",
    "B18",
    "B19",
    "B20",
    "Z01",
    "Z02",
  ];
  
  export const technicalParametersAllowedValues = [
    "B01  ",
    "B02",
    "B03",
    "B04",
    "B05",
    "B06",
    "B09",
    "B14",
    "B15",
    "B17",
    "B20",
  ];
  