import React, { GridDetailRowProps } from "@progress/kendo-react-grid";
import { ReactElement, useEffect } from "react";
import { Box } from "@mui/material";
import TaskType from "../../../api/fixed/Task/TaskType";
import GeneralDetailsView from "./GeneralDetailsView";
import DispatchTaskWithDateAndExternalID from "./DispatchTaskWithDateAndExternalID";
import UpdatePrognosisModelsTaskView from "./UpdatePrognosisModelsTaskView";
import ImportPrognosisTaskView from "./ImportPrognosisTaskView";
import CalculateAndSendDowntimeWorkTaskView from "./CalculateAndSendDowntimeWorkTaskView";
import UpdatePrognosisModelsHistoryTaskView from "./UpdatePrognosisModelsHistoryTaskView";

const DetailsView = ({ dataItem, dataIndex }: GridDetailRowProps): ReactElement => {

    useEffect(() => {
        const elementToDelete = document.getElementById("taskDetailsView")?.parentNode?.parentNode?.firstChild;
        if (elementToDelete) {
            document.getElementById("taskDetailsView")?.parentNode?.parentNode?.removeChild(elementToDelete)
            const ourGrid = document.getElementById("taskDetailsView")?.parentElement?.parentElement?.firstElementChild
            if (ourGrid)
                // @ts-ignore
                ourGrid.colSpan = "6";
        }
    }, [])

    const renderDetails = (): ReactElement => {
        switch (dataItem.type) {
            case TaskType.DispatchCostInfoTask:
            case TaskType.DispatchPlanningDataTask:
            case TaskType.DispatchSensitivitiesTask:
                return <DispatchTaskWithDateAndExternalID dataItem={dataItem} type={dataItem.type}/>
            case TaskType.UpdatePrognosisModelsTask:
                return <UpdatePrognosisModelsTaskView dataItem={dataItem} />
            case TaskType.ImportPrognosisTask:
                return <ImportPrognosisTaskView dataItem={dataItem} />
            case TaskType.SendDowntimeWorkTask:
                return <CalculateAndSendDowntimeWorkTaskView dataItem={dataItem} />
            case TaskType.UpdatePrognosisModelsHistoryTask:
                return <UpdatePrognosisModelsHistoryTaskView dataItem={dataItem} />
            default:
                return <GeneralDetailsView dataItem={dataItem} dataIndex={dataIndex}/>
        }
    }

    return <Box id="taskDetailsView">
        {renderDetails()}
    </Box>
}

export default DetailsView
