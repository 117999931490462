import { createAsyncThunk } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { AxiosError } from "axios";
import i18n from "i18next";
import { TechnicalResource } from "../../../api/fixed/TechnicalResource/TechnicalResource";
import Api from "../../../api/Api";
import { RootState } from "../../../app/store";
import { removeNulls } from "../../../utils";
import {
    addResourceToArray,
    incrementConsumptionRevision,
    incrementProductionRevision,
    incrementResourceRevision,
    setAllResources,
    setConsumption,
    setCurrentResource,
    setProduction
} from "./technicalResourcesSlice";
import { fetchAllControlGroupsAndResources } from "../../appStateSlice";
import { fetchControllableResourceByIdAction, } from "../../controllableResources/store/thunks";
import { setAllDowntimes } from "../../timeseries/downtime/store/slice";
import { setTechnicalResourcesIds } from "../../controllableResources/store/controllableResourcesSlice"

export const createTechnicalResourceAction = createAsyncThunk(
    "technicalResources/create",
    async (resource: TechnicalResource, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState() as RootState;
            if (state.controllableResources.currentResource) {
                const response = await Api.addTechnicalResource(state.controllableResources.currentResource.inventoryItemId!, resource);
                dispatch(addResourceToArray({ inventoryItemId: `${response.data}`, ...resource }))
                dispatch(setTechnicalResourcesIds([...(state.controllableResources.currentResource.technicalResourceIds || []), `${response.data}`]));
            }
        } catch (e) {
            const error = e as AxiosError
            return rejectWithValue({
                status: error.response?.status,
                detail: error.response?.status === 409 ? i18n.t('validation:external_id_not_duplicate') : ""
            })
        }
        return true;
    }
);

export const fetchTechnicalResourceById = createAsyncThunk(
    'technicalResources/fetch',
    async (request: { technicalId: string, force: boolean, setAsCurrent: boolean, controllableId: string }, {
        getState,
        dispatch
    }) => {
        const state: RootState = getState() as RootState;
        const { technicalId, setAsCurrent, force } = request;
        let resource: any | undefined;

        resource = state.technicalResources.allResources?.find(r => r.inventoryItemId === technicalId || r.externalID === technicalId);

        if (!resource || force) {
            resource = removeNulls(await Api.fetchTechnicalResource(technicalId, request.controllableId));
            if (resource) {
                resource = {
                    ...resource,
                    consumesEnergy: !!resource.consumption,
                    producesEnergy: !!resource.production,
                    windPowerCurve: {
                        shutdownWindSpeed: resource.shutdownWindSpeed,
                        netRatedCapacityWindSpeed: resource.netRatedCapacityWindSpeed,
                        elements: resource.windPowerPoints?.map((p: { power: number, speed: number }) => ({
                            value: p.power,
                            speed: p.speed
                        })),
                    }
                };
            }
        }
        if (setAsCurrent) {
            dispatch(setCurrentResource(resource || null));
        }
        return { resource, setAsCurrent };
    }
)

export const fetchTechnicalResourcesByControllableResource = createAsyncThunk(
    "technicalResources/fetchByControllableResource",
    async ({ CRID, force }: { CRID: string, force: boolean }, { getState, dispatch }) => {
        const state: RootState = getState() as RootState;
        let resources;

        resources = state.technicalResources.allResources ?? [];

        if (resources.length === 0 || force) {
            const response = await Api.fetchTechnicalResourcesByControllableResource(CRID);
            resources = response.map(r => ({
                ...removeNulls(r),
                consumesEnergy: removeNulls(!!r.consumption),
                producesEnergy: removeNulls(!!r.production),
                windPowerCurve: {
                    shutdownWindSpeed: r.shutdownWindSpeed,
                    netRatedCapacityWindSpeed: r.netRatedCapacityWindSpeed,
                    elements: r.windPowerPoints?.map(p => ({ value: p.power, speed: p.speed })),
                }
            }));
        }

        dispatch(setAllResources(resources));
    }
)

export const resetTRBeforeEdit = createAsyncThunk(
    "technicalResources/resetBeforeEdit",
    async (_: never, { dispatch, getState }) => {
        const state = getState() as RootState;
        if (!state.controllableResources.currentResource) return;
        if (!state.technicalResources.currentResource) return;

        dispatch(fetchTechnicalResourceById({
            technicalId: state.technicalResources.currentResource.inventoryItemId!,
            setAsCurrent: true,
            force: false,
            controllableId: state.controllableResources.currentResource.inventoryItemId!
        }))
    })

export const updateTechnicalResource = createAsyncThunk(
    "technicalResources/update",
    async (resource: TechnicalResource, { dispatch, getState, rejectWithValue }) => {
        try {
            const state: RootState = getState() as RootState;
            if (!state.controllableResources.currentResource) return true;
            const CRID = state.controllableResources.currentResource.inventoryItemId!
            const resourceFromState = { ...state.technicalResources.allResources?.find(r => r.inventoryItemId === resource.inventoryItemId) };
            let newResource = { ...resource };

            if (!newResource.producesEnergy) {
                newResource.production = undefined;
            }
            if (!newResource.consumesEnergy) {
                newResource.consumption = undefined;
            }

            if (!isEqual(newResource.production, resourceFromState?.production)) {
                if (newResource.production) {
                    const { data } = await Api.upsertMarketLocation(newResource.production, CRID, newResource.inventoryItemId!, false);
                    if (!resourceFromState?.production) {
                        newResource = { ...newResource, production: { ...newResource.production, inventoryItemId: data } };
                    }
                    // eslint-disable-next-line
                    dispatch(incrementProductionRevision());
                } else {
                    await Api.deleteMarketLocation(CRID, newResource.inventoryItemId!, false);
                    // eslint-disable-next-line
                    dispatch(setProduction(undefined));
                }
            }

            if (!isEqual(newResource.consumption, resourceFromState?.consumption)) {
                if (newResource.consumption) {
                    const { data } = await Api.upsertMarketLocation(newResource.consumption, CRID, newResource.inventoryItemId!, true);
                    if (!resourceFromState?.consumption) {
                        newResource = {
                            ...newResource,
                            consumption: { ...newResource.consumption, inventoryItemId: data }
                        };
                    }
                    // eslint-disable-next-line
                    dispatch(incrementConsumptionRevision());
                } else {
                    await Api.deleteMarketLocation(CRID, newResource.inventoryItemId!, true);
                    // eslint-disable-next-line
                    dispatch(setConsumption(undefined));
                }
            }

            await Api.updateTechnicalResource(newResource, CRID);
            dispatch(incrementResourceRevision());
        } catch (e) {
            const error = e as AxiosError
            return rejectWithValue({
                status: error.response?.status,
                detail: error.response?.status === 409 ? i18n.t('validation:external_id_not_duplicate') : ""
            })
        }
        return true;
    }
);

export const buildTRState = createAsyncThunk("technicalResources/buildState",
    async ({
               controllableId,
               technicalId,
               force
           }: { controllableId: string, technicalId: string, force: boolean }, { dispatch }): Promise<void> => {
        await dispatch(fetchAllControlGroupsAndResources({ force, fetchResources: true, fetchGroups: false }))

        const controllableResource = await dispatch(fetchControllableResourceByIdAction({
            id: controllableId,
            force: false,
            setAsCurrent: true
        })).unwrap();

        if (controllableResource && controllableResource.inventoryItemId) {
            await dispatch(fetchTechnicalResourcesByControllableResource({
                CRID: controllableResource.inventoryItemId,
                force
            }));
            if (technicalId !== "add") {
                await dispatch(fetchTechnicalResourceById({
                    technicalId,
                    setAsCurrent: true,
                    force,
                    controllableId: controllableResource.inventoryItemId
                }))
            }
        }
    })

export const forceRefreshTR = createAsyncThunk(
    "technicalResources/forceRefresh",
    async (_: never, { dispatch, getState }) => {
        const state = getState() as RootState;
        if (state.technicalResources.currentResource &&
            state.technicalResources.currentResource.inventoryItemId &&
            state.controllableResources.currentResource &&
            state.controllableResources.currentResource.inventoryItemId) {
            dispatch(buildTRState({
                technicalId: state.technicalResources.currentResource.inventoryItemId,
                controllableId: state.controllableResources.currentResource.inventoryItemId,
                force: true
            }))
        }
    })

export const resetTRState = createAsyncThunk("technicalResources/resetState",
    (_, { dispatch }) => {
        dispatch(setAllDowntimes(undefined));
        dispatch(setCurrentResource(undefined));
    })
