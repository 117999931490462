import { createAsyncThunk } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { AxiosError } from "axios";
import i18n from "i18next";
import { ControllableResource } from "../../../api/fixed/ControllableResource/ControllableResource";
import Api from "../../../api/Api";
import { RootState } from "../../../app/store";
import { removeNulls } from "../../../utils";
import { setAllResources as setAllTechnicalResources } from "../../technicalResources/store/technicalResourcesSlice";
import { setAllSensitivities } from "../../simplePlanningData/sensitivities/store/slice";
import { setAllPlanningData } from "../../timeseries/planningData/store/store";
import { setAllUnavailabilities } from "../../timeseries/unavailability/store/store";
import { setAllFlexibilityConstraints } from "../../timeseries/flexibilityConstraints/store/slice";
import { fetchAllControlGroupsAndResources } from "../../appStateSlice";
import { fetchTechnicalResourcesByControllableResource } from "../../technicalResources/store/thunks";
import {
    addResourceToArray,
    incrementRevision,
    overwriteResourceInArray,
    setAllResources,
    setCurrentResource,
    updateStatusInArray
} from "./controllableResourcesSlice";
import ControllableResourceStatus from "../../../api/fixed/ControllableResource/ControllableResourceStatus";
import { resetReceivedMessages, resetSentMessages } from "../../ConnectPlusMsgs/ConnectPlusMessagesSlice";
import { setAllActivations } from "../../timeseries/activations/store/store";
import { setCurrentProductionData } from "../../timeseries/productionData/store/slice";
import { setAllCostInfo } from "../../costInfo/store/store";
import { setAllGeneratedCostInfo } from "../../timeseries/generatedCostInfo/store/store";
import { resetGeneratedSensitivityState } from "../../timeseries/generatedSensitivities/store/thunks";

export const createControllableResourceAction = createAsyncThunk(
    "controllableResource/create",
    async (resource: ControllableResource, { dispatch, rejectWithValue }) => {
        try {
            const response = await Api.addControllableResource(resource);

            await dispatch(addResourceToArray({
                inventoryItemId: `${response.data}`,
                revision: 0,
                status: ControllableResourceStatus.Received,
                ...resource
            }))
        } catch (e) {
            const error = e as AxiosError
            return rejectWithValue({
                status: error.response?.status,
                detail: error.response?.status === 409 ? i18n.t('validation:external_id_not_duplicate') : ""
            })
        }
        return true;
    }
);

export const deleteTechnicalResource = createAsyncThunk(
    'technicalResources/delete',
    async (req: { CRID: string, TRID: string }, thunkAPI) => {
        const response = await Api.deleteTechnicalResource(req.CRID, req.TRID);
        thunkAPI.fulfillWithValue(response);
    }
)

export const fetchControllableResourceStatusById = createAsyncThunk(
    'controllableResources/fetchStatusById',
    async (id: string, { getState, dispatch }) => {
        const response = await Api.fetchControllableResourceStatus(id);
        const state = getState() as RootState;
        if (!isEqual(state.controllableResources.currentResource?.status, response)) {
            dispatch(updateStatusInArray(response));
        }
    }
)

export const fetchControllableResourceByIdAction = createAsyncThunk(
    'controllableResources/fetchById',
    async ({ id, force, setAsCurrent }: { id: string, force: boolean, setAsCurrent: boolean }, { getState, dispatch }) => {
        const state: RootState = getState() as RootState;
        let resource: ControllableResource | undefined;
        let fromMemory: boolean = true;
        resource = state.controllableResources.allResources?.find(r => r.inventoryItemId === id || r.externalID === id);

        if (!resource || force) {
            let response: any;
            if (resource) {
                response = (await Api.fetchControllableResourceById(resource.inventoryItemId!)).data;
            } else {
                response = (await Api.fetchControllableResourceById(id)).data;
            }

            fromMemory = false;
            resource = removeNulls(response);
        }

        if (setAsCurrent) {
            dispatch(setCurrentResource(resource || null));
        }

        if (!fromMemory && resource) {
            dispatch(overwriteResourceInArray(resource));
        }

        return resource;
    }
);

export const fetchControllableResources = createAsyncThunk(
    'controllableResources/fetch',
    async (force: boolean, { getState, dispatch }) => {
        const state: RootState = getState() as RootState;
        let response;

        if (state.controllableResources.allResources && state.controllableResources.allResources.length > 0 && !force)
            response = [...state.controllableResources.allResources]
        else {
            response = await Api.fetchControllableResources();
            response = response.map(r => removeNulls(r));
        }

        dispatch(setAllResources(response))
    }
)

export const updateControllableResource = createAsyncThunk(
    'controllableResources/update',
    async (resource: ControllableResource, { dispatch, rejectWithValue }) => {
        try {
            await Api.updateControllableResource(resource)
            dispatch(updateStatusInArray(ControllableResourceStatus.Updated))
            dispatch(incrementRevision())
        } catch (e) {
            const error = e as AxiosError
            return rejectWithValue({
                status: error.response?.status,
                detail: error.response?.status === 409 ? i18n.t('validation:external_id_not_duplicate') : ""
            })
        }
        return true;
    }
)

export const approveEnhancedControllableResource = createAsyncThunk(
    "controllableResources/approve",
    async (id: string, { dispatch }) => {
        await Api.enhanceCR(id);
        dispatch(updateStatusInArray(ControllableResourceStatus.Confirmed))
    }
)

export const resetCRState = createAsyncThunk("controllableResources/resetState",
    async (_, { dispatch }) => {
        await dispatch(setAllTechnicalResources(undefined));
        await dispatch(setAllSensitivities(undefined));
        await dispatch(setAllPlanningData(undefined));
        await dispatch(setAllUnavailabilities(undefined));
        await dispatch(setAllFlexibilityConstraints(undefined));
        await dispatch(setAllActivations(undefined));
        await dispatch(setCurrentProductionData(undefined))
        await dispatch(setAllCostInfo(undefined))
        await dispatch(setAllGeneratedCostInfo(undefined))
        await dispatch(resetSentMessages());
        await dispatch(resetReceivedMessages());
        dispatch(resetGeneratedSensitivityState())
    })

export const resetCGBeforeEdit = createAsyncThunk(
    "controllableResources/resetBeforeEdit",
    async (_: never, { dispatch, getState }) => {
        const state = getState() as RootState;
        if (!state.controllableResources.currentResource) return;

        dispatch(fetchControllableResourceByIdAction({
            id: state.controllableResources.currentResource.inventoryItemId!,
            setAsCurrent: true,
            force: false,
        }))
    })


export const buildCRState = createAsyncThunk("controllableResources/buildState",
    async ({
               id,
               force,
               fetchTRs,
           }: { id: string, force: boolean, fetchTRs: boolean }, { dispatch }): Promise<ControllableResource | undefined> => {
        await dispatch(fetchAllControlGroupsAndResources({ force, fetchResources: true, fetchGroups: false }))

        const fetchResponse = await dispatch(fetchControllableResourceByIdAction({
            id,
            force,
            setAsCurrent: true
        })).unwrap();
        if (fetchResponse && fetchResponse.inventoryItemId) {
            if (fetchTRs)
                await dispatch(fetchTechnicalResourcesByControllableResource({
                    CRID: fetchResponse.inventoryItemId,
                    force
                }));
        }

        return fetchResponse;
    })

export const forceRefreshCR = createAsyncThunk("controllableResources/forceRefresh",
    async (_: never, { dispatch, getState }) => {
        const state = getState() as RootState;
        if (state.controllableResources.currentResource) {
            await dispatch(buildCRState({
                id: state.controllableResources.currentResource.inventoryItemId!,
                force: true,
                fetchTRs: true
            }));
        }
    })
